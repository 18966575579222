import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { db, storage } from '../utils/firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { renderToString } from "react-dom/server";
import jsPDF from 'jspdf';
import { collection, addDoc, Timestamp, updateDoc, doc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { allDependentInputs } from './shared/input_index_names'
import Logo from '../img/Coat_of_arms_of_Ghana.svg.png'
import _ from 'lodash';
import CountryData from './shared/countries.json'
import Spinner from './shared/Spinner';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Rodal from 'rodal';
import UploadError from '../img/delete.png';
import { isMobile } from 'react-device-detect';
import emailjs from '@emailjs/browser';

// include styles
import 'rodal/lib/rodal.css'

const date = new Date();
let errorIndex;
let formIndex;


const handleSubmit = async (location, setSpinner, navigate, setModalVisible) => {
  // // console.log('here here here here here here')

  // SET SPINNER VARIABLE IN ASYNC

  setSpinner(true)

  const {
    visatype, purpose, passportexpiry, passportno, passporttype, paymethod, fundingsource, dateofarrival,nationality, issuedat, previousvisit, dateofvisit, othernationality,
    firstname, othername, surname, dob, cob, pob, occupation, contact, residentialaddress, email, confirmemail,
    deported, criminal, communicable, mental, yellowfever, ID, accesscode, othernationality2, otherpurpose, modeoftravel,
    hostfullname, hostaddress, hostcountry, hostcontact, hotelfullname, hoteladdress, hotelcountry, hotelcontact,
    nextofkinfullname, nextofkinaddress, nextofkincountry, nextofkincontact, employer, academic, isastudent,
    deportedcountry, deporteddate, deportedreason, deportedfromghdate, deportedfromghreason, deportedfromgh, yellowfeverdate,
    matchIndex, matchID,

  } = location.state.data;
  // console.log(matchID);
  // console.log(matchIndex)
  // console.log(passportno)

  // ALWAYS REQUIRED CHECKS
  const arrangedData = [
    firstname,
    othername,
    surname,
    dob, // date of birth
    pob,   // place of birth
    cob,    // country of birth

    contact,
    occupation,
    residentialaddress,
    isastudent,
    academic,
    employer,
    email,
    confirmemail,


    visatype,
    paymethod,
    previousvisit,
    dateofvisit,
    nationality,
    passporttype,
    passportno,
    issuedat,
    passportexpiry,
    othernationality,
    othernationality2,
    purpose,

    dateofarrival,

    otherpurpose,
    fundingsource,
    modeoftravel,
    

    hostfullname,
    hostaddress,
    hostcountry,
    hostcontact,

    hotelfullname,
    hoteladdress,
    hotelcountry,
    hotelcontact,

    nextofkinfullname,
    nextofkinaddress,
    nextofkincountry,
    nextofkincontact,



    deportedfromgh,
    deportedfromghdate,
    deportedfromghreason,

    deported,
    deportedcountry,
    deporteddate,
    deportedreason,

    criminal,
    mental,
    communicable,
    yellowfever,
    yellowfeverdate,
  ]
  // console.log(arrangedData)

  // FIND NOT REQUIRED INPUTs BY FILTERING THE TEMPORARY REQUIRED / TRIGGERED REQUIRED FROM THE DEPENDENT INPUTS
  const notRequired = allDependentInputs.filter(x => !location.state.tempRequired.includes(x))
  // console.log('NOT REQUIRED IS --> ')
  // console.log(notRequired)
  for (let x = 0; x < arrangedData.length; x++) {
    // console.log('run')
    if (arrangedData[x] === '') {

      if (!notRequired.includes(x)) {
        errorIndex = x;
        formIndex = x < 14 ? 0 : (x < 42 ? 1 : 2)
        // console.log('Error Index Is' + errorIndex)
        setSpinner(false);

        setModalVisible(true)
        return;

      } else {
        // console.log('Index ' + x + ' is empty and not required')
      }
    }

  }

  // console.log('All Conditions passed')


  if (email === confirmemail) {


     if (matchIndex > -1) {
      // console.log(matchID)
      const updateRef = doc(db, 'applications', matchID);
      await updateDoc(updateRef, {
          ID: ID,
          accesscode: accesscode,
  
          Visatype: visatype,
          Purpose: purpose,
          Passport_Expiry: passportexpiry,
          Passport_Number: passportno,
          Passport_Type: passporttype,
          Pay_Method: paymethod,
          Funding_Source: fundingsource,
          Nationality: nationality,
          Passport_Issued_At: issuedat,
          Previous_Visit: previousvisit,
          Date_Of_Previous_Visit: dateofvisit,
          // Next_Of_Kin: nextofkin,
          // Name_Address_of_Contact_Person_In_Ghana: hostid,
          // Hotel_Address: hotelid,
          Other_Nationality: othernationality,
  
          Other_Nationality2: othernationality2,
          Other_Purpose: otherpurpose,
          Mode_Of_Travel: modeoftravel,
          Expected_Arrival_Date: dateofarrival,
  
          Host_FullName: hostfullname,
          Host_Address: hostaddress,
          Host_Contact: hostcontact,
          Host_Country: hostcountry,
  
          Hotel_FullName: hotelfullname,
          Hotel_Address: hoteladdress,
          Hotel_Country: hotelcountry,
          Hotel_Contact: hotelcontact,
  
          NextOfKin_FullName: nextofkinfullname,
          NextOfKin_Address: nextofkinaddress,
          NextOfKin: nextofkincountry,
          NextOfkin_Contact: nextofkincontact,
  
          Firstnames: firstname,
          Othername: othername,
          Surname: surname,
          Date_Of_Birth: dob,
          Country_Of_Birth: cob,
          Place_Of_Birth: pob,
          Occupation: occupation,
          Contact: contact,
  
          ResidentialAddress: residentialaddress,
          Email: email, // check with confirm email before submit..
  
          Employer: employer,
          Academic: academic,
          Is_A_Student: isastudent,
  
  
  
          Deported_From_Country_Status: deported,
          Deported_From_GH_Status: deportedfromgh,
  
          Deported_Country: deportedcountry,
  
          Deported_From_GH_Reason: deportedfromghreason,
          Deported_From_Country_Reason: deportedreason,
  
          Deported_From_GH_Date: deportedfromghdate,
          Deported_From_Country_Date: deporteddate,
  
          Criminal_Status: criminal,
          Communicable_Disease_Status: communicable,
          Mental_Status: mental,
          Yellow_Fever_Status: yellowfever,
          Yellow_Fever_Date: yellowfeverdate,
  
  
          passwordUsed: false,
          Link_To_Files: '',
  
          STATUS: 0,
  
          updated: Timestamp.now()

      })

      exportPDF(location, setSpinner, navigate);

     } else {
      console.log(matchID)
       
      try {

    
        const addRef = await addDoc(collection(db, 'applications'), {
          ID: ID,
          accesscode: accesscode,
  
          Visatype: visatype,
          Purpose: purpose,
          Passport_Expiry: passportexpiry,
          Passport_Number: passportno,
          Passport_Type: passporttype,
          Pay_Method: paymethod,
          Funding_Source: fundingsource,
          Nationality: nationality,
          Passport_Issued_At: issuedat,
          Previous_Visit: previousvisit,
          Date_Of_Previous_Visit: dateofvisit,
          // Next_Of_Kin: nextofkin,
          // Name_Address_of_Contact_Person_In_Ghana: hostid,
          // Hotel_Address: hotelid,
          Other_Nationality: othernationality,
  
          Other_Nationality2: othernationality2,
          Other_Purpose: otherpurpose,
          Mode_Of_Travel: modeoftravel,
          Expected_Arrival_Date: dateofarrival,
  
          Host_FullName: hostfullname,
          Host_Address: hostaddress,
          Host_Contact: hostcontact,
          Host_Country: hostcountry,
  
          Hotel_FullName: hotelfullname,
          Hotel_Address: hoteladdress,
          Hotel_Country: hotelcountry,
          Hotel_Contact: hotelcontact,
  
          NextOfKin_FullName: nextofkinfullname,
          NextOfKin_Address: nextofkinaddress,
          NextOfKin: nextofkincountry,
          NextOfkin_Contact: nextofkincontact,
  
          Firstnames: firstname,
          Othername: othername,
          Surname: surname,
          Date_Of_Birth: dob,
          Country_Of_Birth: cob,
          Place_Of_Birth: pob,
          Occupation: occupation,
          Contact: contact,
  
          ResidentialAddress: residentialaddress,
          Email: email, // check with confirm email before submit..
  
          Employer: employer,
          Academic: academic,
          Is_A_Student: isastudent,
  
  
  
          Deported_From_Country_Status: deported,
          Deported_From_GH_Status: deportedfromgh,
  
          Deported_Country: deportedcountry,
  
          Deported_From_GH_Reason: deportedfromghreason,
          Deported_From_Country_Reason: deportedreason,
  
          Deported_From_GH_Date: deportedfromghdate,
          Deported_From_Country_Date: deporteddate,
  
          Criminal_Status: criminal,
          Communicable_Disease_Status: communicable,
          Mental_Status: mental,
          Yellow_Fever_Status: yellowfever,
          Yellow_Fever_Date: yellowfeverdate,
  
  
          passwordUsed: false,
          Link_To_Files: '',
  
          STATUS: 0,
  
          created: Timestamp.now()
        })
  
  
        // console.log(addRef.id)
  
  
  
        // CONVERT HTML FORM TO PDF AND ATTACH TO EMAIL
        exportPDF(location, setSpinner, navigate);
  
        // console.log('Function must return and cannot print this statement')
  
      } catch (err) {
        alert(err)
      }
      

     }

    
    
  } else {
    alert('Email Mismatch')
  }
}

const uploadPDF = async (blobFile, location, setSpinner, navigate) => {
  setSpinner(true);

  const storageRef = ref(storage, `applications/${location.state.data.ID}.pdf`)
  const uploadTask = uploadBytesResumable(storageRef, blobFile);

  uploadTask.on(
    'state_changed', (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );

      // console.log(percent)
    }, (error) => console.log(error),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        // console.log(url)
        // setPdfLink(url)
        /*
         let hashLink = CryptoJS.AES.encrypt(location.state.data.id, 'my-secret-key@123').toString(); // SECRET KEY IS GOING TO BE AN ENV VARIABLE
         // console.log(hashLink)
         const convertedHashLink = hashLink.replace(/\+/g, 'xMl3Jk').replace(/\//g, 'Por21Ld').replace(/=/g, 'Ml32');
         // console.log('New Hashlink ->' + convertedHashLink)
         */

        let hashLink = encodeLink(location.state.data)
        const hashURL = 'https://ghanavisabm.netlify.app/login/' + hashLink
        sendMail(location, hashURL, setSpinner, url, navigate)

        /*
        try {

          window.Email.send({
            Host: "smtp.elasticemail.com",

            Username: "fariescovington@gmail.com",
            Password: "4BF2549F1B35E01ADBCDF9D6C313387BDA85",
            To: location.state.data.email, // Use the email entered by user
            From: "fariescovington@gmail.com",
            Subject: "Application for Ghana Visa",

            Attachments: [{
              name: `${location.state.data.ID}.pdf`,
              path: url,
            }],

            Body: `<div>
                      Dear ${location.state.data.firstname} ${location.state.data.surname}, <br /> <br />
                      Application Reference Number: ${location.state.data.ID} <br />
                      Access Code:  ${location.state.data.accesscode} <br />
                      <br /><br />
                      Your application is now registered with the Consulate and processing will commence as soon as we receive the applicant's photograph and supporting documents. 
                      To securely upload all supporting documents, <a href="https://courageous-panda-1565f8.netlify.app/login/${hashLink}"> click on this link</a> 
                      <br /><br />
                      You will need the Reference Number and Access Code above to upload supporting documents and for all enquiries regarding your application.
                      <br /><br />
                      <font color="red">
                      Please note the Reference Number/Access Code combination can be used only once. 
                      This means you will NOT be able to use this information more than once to upload the applicant's photo and supporting documents. 
                      You must therefore only upload the documents only when you have all the documents listed below ready.
                      </font>
                      <br />
                      You must at a minimum upload the following documents in support of your application:
                      <br /><br />
                      (1) A digital passport picture in jpeg or png format. It should be clear and in focus, in colour, unaltered by computer software, at least 600 pixels wide and 750 pixels tall and at least 50KB and no more than 10MB;
                      <br /><br />
                      (2) Biodata/information page of the passport/travel document on which the applicant will be travelling;
                      <br /><br />
                      (3) Yellow Fever Vaccination Card;
                      <br /><br />
                      (4) Reference Letter from the person inviting you to Ghana - this must include either a scanned image of the person's passport or Ghana Card;
                      <br /><br />
                      (5) Name and address of the Hotel (incl. booking/reservation number) where the applicant will be staying during this trip to Ghana;
                      <br /><br />
                      (6) A signed copy of the registered Application Form (an unsigned copy is attached to this notification email) and
                      <br /><br />
                      (7) Evidence of payment of Visa fee.
                      <br /><br />
                      Note: Apart from the passport picture, all uploaded documents must be in pdf.
                      </div>
                    `
          }).then(message => {
            if (message.toLowerCase() === 'ok') {

              // const deleteRef = ref(storageRef)
              deleteObject(storageRef).then(() => {
                // console.log('File deleted on server')

                notifyAdmins(location, setSpinner, navigate);

              }).catch((err) => {
                // console.log('File NOT deleted')
                setSpinner(false)
                // console.log(err)

              })


            } else {
              alert(message)
              setSpinner(false)

            }

          });

        } catch (e) {
          // console.log(e)

        }
        */

      })
    }
  )
}

const sendMail = (location, hashlink, setShowSpinner, applicationURL, navigate) => {
  
  const mailParams = {
    to_email: location.state.data.email,
    to_name: `${location.state.data.firstname} ${location.state.data.surname}`,
    to_hashlink: hashlink,
    to_ref: location.state.data.ID,
    to_code: location.state.data.accesscode,
    attachment: applicationURL,
  }

  emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_avb9rup', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {

    // NOTIFY ADMINS..
    notifyAdmins(location, setShowSpinner, navigate)
  }, (error) => {
    setShowSpinner(false)
    // console.log(error)
  })
}

const notifyAdmins = (location, setSpinner, navigate) => {

  const mailParams = {
    to_email: 'nanaotu@gmail.com',
    name: `${location.state.data.firstname} ${location.state.data.surname}`,
    typeOfVisa: location.state.data.visatype,
    purpose: location.state.data.purpose,
    expectedArrivalDate: location.state.data.dateofarrival.toDateString(),
    vaccinated: location.state.data.yellowfever,
    
  }
  // emailjs.send('service_svcc7f9', 'template_tre9xnf', mailParams, 'OA-bVDW950X7KHwjT').then(response => {
  emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_41qa3nn', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {
    setSpinner(false)

        alert('An Email would be sent to you in few minutes')
        // console.log('Admins Notified')
        navigate('/', { replace: true, state: {} })
    // NOTIFY ADMINS..
  }, (error) => {
    setSpinner(false)
    // console.log('Admins email NOT sent')
    // console.log(error)
  })
  /*

  try {
    window.Email.send({
      Host: "smtp.elasticemail.com",

      Username: "fariescovington@gmail.com",
      Password: "4BF2549F1B35E01ADBCDF9D6C313387BDA85",
      To: 'seffah999@gmail.com',
      From: "fariescovington@gmail.com",
      Subject: "New Application from Hon. Bernuda Consulate",

      Body: `<div>
      Hello Admin, 
      
        <br />    
          A new application with ID <font color="red"> ${location.state.data.ID} </font> has been registered. 
        <br />
      Kindly review.
      THANK YOU.
            
      </div>
    `
    }).then(message => {
      if (message.toLowerCase() === 'ok') {
        setSpinner(false)

        alert('Check Your email')
        // console.log('Admins Notified')
        navigate('/', { replace: true, state: {} })

      } else {
        setSpinner(false)

        alert('Check Your email')
        // console.log('Admins email NOT sent')
      }
    }
    )

  } catch (e) {
    // console.log(e)

  }
  */
  
}


const encodeLink = (state) => {
  let code = "";

  const possibleChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz$_.!*(),";

  for (let i = 0; i < 200; i++)
    code += possibleChar.charAt(Math.floor(Math.random() * possibleChar.length));

  let text = code.split('');

  // console.log(state.ID)
  // console.log(state.ID.length)

  text[144] = state.ID[0]
  text[7] = state.ID[1]
  text[15] = state.ID[2]
  text[21] = state.ID[3]
  text[29] = state.ID[4]
  text[36] = state.ID[5]
  text[45] = state.ID[6]
  text[50] = state.ID[7]
  text[59] = state.ID[8]
  text[63] = state.ID[9]

  /*
  text[68] = state.ID[10]
  text[72] = state.ID[11]
  text[99] = state.ID[12]
  text[123] = state.ID[13]
  text[100] = state.ID[14]
  text[178] = state.ID[15]
  text[88] = state.ID[16]
  */


  text = text.join('');
  // console.log('ENCRYPTED URL -->' + text)


  return text;
}

const exportPDF = (location, setSpinner, navigate) => {


  const elementPage1 = (

    <div className='print-template'>

      <div className="print-template__card">

        <div className="print-template__head">
          <div className="print-template__head__logobox">
            <img src={Logo} alt="Logo of the company" className="print-template__head__img" />
            <div className="print-template__head__sidebox">
              <p className="print-template__head__text">Honorary Consulate of Ghana</p>
              <p className="print-template__head__text">Hamilton Bremuda</p>
            </div>
          </div>
        </div>



        <div className="print-template__top">
          VISA APPLICATION
        </div>

        <div className="print-template__section__container">

          <div className="print-template__section">
            <p className="print-template__section__head">Part 1: Personal Information</p>

            <div className="p print-template__section__box">

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(a) First names <span>*</span></span>
                </div>
                <div className="print-template__col14__info">
                  {location.state.data.firstname}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(b) Other Name(s)</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.othername}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(c) Surname <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.surname}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(d) Date of Birth <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.dob.toDateString()}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(e) Place of birth <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.pob}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(f) Country of Birth <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.cob}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(g) Contact Telephone <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.contact}
                </div>
              </div>
              {/*

                <div className="print-template__col14 print-template__col12">
                  <div className="print-template__col14__headbox">
                    <span className="print-template__col14__head">(g) Contact Country <span>*</span></span>

                  </div>
                  <div className="print-template__col14__info">
                    {location.state.data.phoneCountry}
                  </div>
                </div>

            */}


              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(h) Occupation <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.occupation}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(i) Full Residential Address <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.residentialaddress}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(j) Are You a Student <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.isastudent}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(k) Name Of Academic Instituition <span>{location.state.data.isastudent === "Yes" ? `*` : ''}</span></span>
                </div>
                <div className="print-template__col14__info">
                  {location.state.data.academic}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(l) Name of Employer / Previous Employer</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.employer}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(m) Contact Email <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.email}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(n) Confirm Contact Email <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.confirmemail}
                </div>
              </div>

            </div>

          </div>

          <div className="print-template__section">
            <p className="print-template__section__head">Part 2: Travel Information</p>

            <div className="print-template__section__box">

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(a) Visa Required <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.visatype}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(b) Purpose of Trip <span>*</span> </span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.purpose}
                </div>
              </div>

              <div className="print-template__col14 print-template__col11">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(c) If Other (Please State)</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.otherpurpose}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12  u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(d) Travel Passport Nationality <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.nationality}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(e) Type of Passport <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.passporttype}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(f) Passport Number <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.passportno}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(g) Passport Issued At <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.issuedat}
                </div>
              </div>

              <div className="print-template__col14 print-template__col11">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(h) Passport Expiry <span>*</span></span>

                </div>
                <div className="print-template__col14__info">

                  {location.state.data.passportexpiry.toDateString()}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(i) Other/Prev. Nationality (1)</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.othernationality}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(j) Other/Prev. Nationality (2)</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.othernationality2}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(k) Method Of Payment <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.paymethod}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(l) Source of funding <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.fundingsource}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(m) Previously Visited Ghana <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.previousvisit}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(n) Date of last Exit From Ghana</span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.previousvisit === 'Yes' ? location.state.data.dateofvisit.toDateString() : ''}
                </div>
              </div>

              <div className="print-template__col14 print-template__col12 u-margin-bottom u-no-border-right">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(o) Mode Of Travel <span>*</span></span>

                </div>
                <div className="print-template__col14__info">
                  {location.state.data.modeoftravel}
                </div>
              </div>


              <div className="print-template__col14 print-template__col12 u-margin-bottom">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(p) Date Of Arrival</span>
                  <span className="print-template__col14__required">&#10059;</span>
                </div>
                <div className="print-template__col14__info">
                  {location.state.data.dateofarrival ? location.state.data.dateofarrival.toDateString() : ''}
                </div>
              </div>

              <div className="print-template__col13__container">

                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(p) Full Name, Phone Number And Address of Host in Ghana</span>

                </div>

                <div className="print-template__col13__box u-no-border">

                  <div className="print-template__col13__info">
                    {location.state.data.hostfullname}
                  </div>


                  <div className="print-template__col13__info u-no-border">
                    {location.state.data.hostaddress}
                  </div>

                  <div className="print-template__col13__info u-no-border">
                    {location.state.data.hostcontact}
                  </div>

                </div>

              </div>


              <div className="print-template__col13__container">

                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(q) Address/Hotel Where Applicant Will Be Staying</span>

                </div>

                <div className="print-template__col13__box u-no-border">

                  <div className="print-template__col13__info u-no-border">
                    {location.state.data.hotelfullname}
                  </div>


                  <div className="print-template__col13__info u-no-border">

                    {location.state.data.hoteladdress}

                  </div>

                  <div className="print-template__col13__info u-no-border">


                    {location.state.data.hotelcontact}

                  </div>

                </div>
              </div>

              <div className="print-template__col13__container">
                <div className="print-template__col14__headbox">
                  <span className="print-template__col14__head">(r) Name & Contact of Next-of-Kin <span>*</span></span>

                </div>


                <div className="print-template__col13__box u-no-border">

                  <div className="print-template__col13__info u-no-border">
                    {location.state.data.nextofkinfullname}
                  </div>


                  <div className="print-template__col13__info u-no-border">

                    {location.state.data.nextofkinaddress}

                  </div>

                  <div className="print-template__col13__info u-no-border">


                    {location.state.data.nextofkincontact}

                  </div>

                </div>
              </div>


            </div>
          </div>

        </div>


      </div>
    </div>

  );

  const elementPage2 = (
    <div className='print-template'>

      <div className="print-template__card u-no-padding-bottom">
        <div className="print-template__section">
          <p className="print-template__section__head">Part 3: Declarations</p>

          <div className="print-template__section__box">

            <div className="print-template__col14 print-template__col12 u-no-border-right">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(a) Ever been refused entry to / deported from Ghana ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deportedfromgh}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(c) Date deported / refused entry to Ghana</span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deportedfromgh === 'Yes' ? location.state.data.deportedfromghdate.toDateString() : ''}
              </div>
            </div>

            <div className="print-template__col14 print-template__col11">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(e) Reason for Being from deported / refused entry to Ghana</span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deportedfromghreason}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12 u-no-border-right">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(b) Ever been refused entry to / deported any Country ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deported}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(d) Date deported from / refused entry to Other Country</span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deported === 'Yes' ? location.state.data.deporteddate.toDateString() : ''}
              </div>
            </div>



            <div className="print-template__col14 print-template__col11">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(f) Reason for Being from deported from / refused entry to Other Country</span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deportedreason}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12 u-no-border-right">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(b) Which Country were you been refused entry to / deported from ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.deportedcountry}
              </div>
            </div>




            <div className="print-template__col14 print-template__col12">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(g) Do you have a criminal record in any country ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.criminal}
              </div>
            </div>





            <div className="print-template__col14 print-template__col12 u-no-border-right">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(h) Do you suffer from any mental Disorders ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.mental}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(i) Do you suffer from any communicable diseases ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.communicable}
              </div>
            </div>

            <div className="print-template__col14 print-template__col12 u-no-border-right u-margin-bottom">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head">(j) Have you had Yellow Fever Vaccination ? <span>*</span></span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.yellowfever}
              </div>
            </div>



            <div className="print-template__col14 print-template__col12 u-margin-bottom">
              <div className="print-template__col14__headbox">
                <span className="print-template__col14__head" >(k) Date of Yellow Fever Vaccination</span>

              </div>
              <div className="print-template__col14__info">
                {location.state.data.yellowfever === "Yes" ? location.state.data.yellowfeverdate.toDateString() : ''}
              </div>
            </div>



          </div>

        </div>


        <div className="print-template__terms">

          <div className='print-template__terms__item'>

            I believe and declare that the information given in this application by me or on my behalf are true and that
            any documents provided in relation to this application are genuine to the best of my knowledge. I do
            consent to the processing of the information and documents according to Ghanaian law for the purposes of
            obtaining a Visa and to be held liable according to that law if any information or documents are found to be
            untruthful.
          </div>

        </div>
        <div className='print-template__terms__sign'>

          <div className="print-template__col14 print-template__col12 u-margin-bottom">
            <div className="print-template__col14__headbox">
              <span className="print-template__col14__head">Date</span>

            </div>
            <div className="print-template__col14__info">
              &nbsp;
            </div>
          </div>

          <div className="print-template__col14 print-template__col12 u-margin-bottom">
            <div className="print-template__col14__headbox">
              <span className="print-template__col14__head">Signature</span>

            </div>
            <div className="print-template__col14__info">
              &nbsp;
            </div>
          </div>
        </div>


        <div className="print-template__official">
          <h2 className='print-template__official__head'>Official Use Only</h2>
          <div className='print-template__official__box'>

            <div className="print-template__official__list">
              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Visa Application Number
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Type of Visa
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Visa Fee
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Payment Reference Number
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Visa Label Number
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Receipt Number
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Date of Issue
                </div>
                <div className="print-template__official__list__item__box" />
              </div>

              <div className="print-template__official__list__item">
                <div className="print-template__official__list__item__label">
                  Issuing Officer
                </div>
                <div className="print-template__official__list__item__box" />
              </div>
            </div>

            <div className="print-template__official__passportbox" >
              <div className="print-template__official__passportitem">Passport pic</div>
            </div>
          </div>



        </div>

        <div className="print-template__footer">
          <p>Visa Application Form</p>
          <p>Page 2/2</p>
        </div>
      </div>
    </div>


  )
  setSpinner(true)
  const doc = new jsPDF("p", "pt", "a4");

  // const elementPage1Text = renderToString(elementPage1);
  // const elementPage2Text = renderToString(elementPage2);
  doc.setFont('Helvetica')
  // // console.log(doc.getFontList())


  try {
    setSpinner(true)
    // console.log('Inside try catch')
    doc.html(renderToString(elementPage1), {

      callback: function (doc) {
        // doc.addPage()
        doc.html(renderToString(elementPage2), {
          x: 0,
          y: 850,
          callback: function (doc) {

            // console.log('Doc done converting')
            doc.save(`${location.state.data.ID}.pdf`);
            // SAVE THIS ON THE FIREBASE SERVER BEFORE YOU ADD AS ATTACHMENT
            const blob = doc.output('blob');
            // setSpinner(false)
            // Upload blob file to Firebase storage.
            uploadPDF(blob, location, setSpinner, navigate);

          }


        }
        )
      }
    })
  } catch (err) {
    // console.log('Error from ReactDomRenderer')
    // console.log(err)
  }



  // // console.log('Im i running bro. Sad')


};

const handleRodalClose = (setModalVisible, navigate, location) => {
  setModalVisible(false)

  navigate('/', { replace: true, state: { pageIndex: formIndex, errorIndex, formData: location.state.data } })
}






// MAIN TEMPLATE COMPONENT

export const Template = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [showSpinner, setShowSpinner] = useState(false)
  const [modalVisible, setModalVisible] = useState(false);
  // console.log(isMobile)


  return (
    <div className='template'>
      <Header />
      <Spinner showSpinner={showSpinner} />


      <Rodal
        visible={modalVisible}
        width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.5}
        height={isMobile ? 320 : 420}
      >
        <div className='myrodal'>
          <div className="myrodal__iconbox">
            <img src={UploadError} alt="File upload sucesss" className='myrodal__icon' />
          </div>
          <div className="myrodal__message">

            There was a problem in one of the fields. Press Ok to return to the form and do the necessary corrections.
            The Field would be highlighted <span>RED</span> to help you do the corrections

          </div>

          <div className="myrodal__btnContainer" onClick={() => handleRodalClose(setModalVisible, navigate, location)}>
            <button className="btn btn--normal">Ok</button>
          </div>

        </div>
      </Rodal>


      <div className="template__card" style={showSpinner ? { display: 'none', width: '0' } : {}}>

        <div className="template__head">
          <div className="template__head__logobox">
            <img src={Logo} alt="Logo of the company" className="template__head__img" />
            <div className="template__head__sidebox">
              <p className="template__head__text">Honorary Consulate of Ghana</p>
              <p className="template__head__text">Hamilton Bremuda</p>
            </div>
          </div>
          {/*
          <p className="template__head__price">315.00</p>
        */}
        </div>

        {/*
        <div className="template__barcode">
          <img src={Barcode} alt="" className="template__barcode__img" />
          <p className="template__barcode__id">{location.state.data.ID}</p>
        </div>
        */}

        <div className="template__top">
          VISA APPLICATION
        </div>
        {
          /*
          <div className="template__top">

          <div className="template__top__labels">
            <p className="template__top__labels__item">Visa Number:</p>
            <p className="template__top__labels__item">Visa Type:</p>
            <p className="template__top__labels__item">Visa Fee($):</p>
            <p className="template__top__labels__item">Receipt No:</p>
            <p className="template__top__labels__item">Date of Issue:</p>
            <p className="template__top__labels__item">Issuing Officer:</p>
          </div>

          <div className="template__top__heading">
            <p className="template__top__heading__main">Application For Ghana Visa</p>
            <p className="template__top__heading__sub">
              Please read the guidance notes carefully before completing this form.
              This form must be completed electronically.All fields marked asterisk &#10059;
              are mandatory.
              All information must be typed in English and all dates in format dd/mm/yyyy
            </p>
          </div>

          <div className="template__top__passport">
            Passport Photo
          </div>
        </div>
          */
        }



        <div className="template__section__container">

          <div className="template__section">
            <p className="template__section__head">Part 1: Personal Information</p>

            <div className="p template__section__box">

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(a) First names</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.firstname}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(b) Prev./Other Name(s)</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.othername}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(c) Surname</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.surname}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(d) Date of Birth </span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.dob ? location.state.data.dob.toDateString() : ''}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(e) Place of birth</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.pob}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(f) Country of Birth</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.cob}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(g) Contact Telephone</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.contact}
                </div>
              </div>

              {/* 
              <div className="template__col14 template__col12">
                  <div className="template__col14__headbox">
                    <span className="template__col14__head">(g) Contact Country</span>
                    <span className="template__col14__required">&#10059;</span>
                  </div>
                  <div className="template__col14__info">
                    {location.state.data.phoneCountry}
                  </div>
                </div>
            */}


              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(h) Occupation</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.occupation}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(i) Full Residential Address</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.residentialaddress}
                </div>
              </div>

              <div className="template__col14 template__col12 u-margin-bottom">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(j) Are You a Student</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.isastudent}
                </div>
              </div>

              <div className="template__col14 template__col12 u-margin-bottom u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(k) Name Of Academic Instituition</span>
                  <span className="template__col14__required">{location.state.data.isastudent === "Yes" ? `*` : ''}</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.academic}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(l) Name of Employer / Previous Employer</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.employer}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(m) Contact Email</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.email}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(n) Confirm Contact Email</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.confirmemail}
                </div>
              </div>



            </div>

          </div>

          <div className="template__section">
            <p className="template__section__head">Part 2: Travel Information</p>

            <div className="template__section__box">

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(a) Visa Required</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.visatype}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(b) Purpose of Trip</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.purpose}
                </div>
              </div>

              <div className="template__col14 template__col11">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(c) If Other (Please State)</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.otherpurpose}
                </div>
              </div>

              <div className="template__col14 template__col12  u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(d) Travel Passport Nationality</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.nationality}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(e) Type of Passport </span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.passporttype}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(f) Passport Number</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.passportno}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(g) Passport Issued At</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.issuedat}
                </div>
              </div>

              <div className="template__col14 template__col11">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(h) Passport Expiry</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">

                  {location.state.data.passportexpiry ? location.state.data.passportexpiry.toDateString() : ''}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(i) Other/Prev. Nationality (1)</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.othernationality}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(j) Other/Prev. Nationality (2)</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.othernationality2}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(k) Method Of Payment</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.paymethod}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(l) Source of funding</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.fundingsource}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(m) Previously Visited Ghana</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.previousvisit}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(n) Date of last Visit</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.dateofvisit ? location.state.data.dateofvisit.toDateString() : ''}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(o) Mode Of Travel</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.modeoftravel}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(p) Date Of Arrival</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.dateofarrival ? location.state.data.dateofarrival.toDateString() : ''}
                </div>
              </div>

              <div className="template__col13__container">

                <div className="template__col14__headbox">
                  <span className="template__col14__head">(q) Full Name, Phone Number And Address of Host in Ghana</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>

                <div className="template__col13__box u-no-border">

                  <div className="template__col13__info">
                    {location.state.data.hostfullname}
                  </div>


                  <div className="template__col13__info u-no-border">
                    {location.state.data.hostaddress}
                  </div>

                  <div className="template__col13__info u-no-border">
                    {location.state.data.hostcontact}
                  </div>

                </div>

              </div>


              <div className="template__col13__container">

                <div className="template__col14__headbox">
                  <span className="template__col14__head">(r) Address/Hotel Where Applicant Will Be Staying</span>
                  <span className="template__col14__required">&nbsp;</span>
                </div>

                <div className="template__col13__box u-no-border">

                  <div className="template__col13__info u-no-border">
                    {location.state.data.hotelfullname}
                  </div>


                  <div className="template__col13__info u-no-border">

                    {location.state.data.hoteladdress}

                  </div>

                  <div className="template__col13__info u-no-border">


                    {location.state.data.hotelcontact}

                  </div>

                </div>
              </div>

              <div className="template__col13__container">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(s) Name & Contact of Next-of-Kin</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>


                <div className="template__col13__box u-no-border">

                  <div className="template__col13__info u-no-border">
                    {location.state.data.nextofkinfullname}
                  </div>


                  <div className="template__col13__info u-no-border">

                    {location.state.data.nextofkinaddress}

                  </div>

                  <div className="template__col13__info u-no-border">


                    {location.state.data.nextofkincontact}

                  </div>

                </div>
              </div>


            </div>
          </div>



          <div className="template__section">
            <p className="template__section__head">Part 3: Declarations</p>

            <div className="template__section__box">

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(a) Ever been refused entry to / deported from Ghana ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deportedfromgh}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(c) Date deported from Ghana</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deportedfromghdate ? location.state.data.deportedfromghdate.toDateString() : ''}
                </div>
              </div>

              <div className="template__col14 template__col11">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(e) Reason for Being from deported from Ghana</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deportedfromghreason}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(b) Ever been refused entry to / deported any Country ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deported}
                </div>
              </div>




              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(d) Date deported from Other Country</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deporteddate ? location.state.data.deporteddate.toDateString() : ''}
                </div>
              </div>




              <div className="template__col14 template__col11">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(f) Reason for Being from deported from Other Country</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deportedreason}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(b) Which Country were you refused entry to / deported from ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.deported}
                </div>
              </div>




              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(g) Do you have a criminal record in any country ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.criminal}
                </div>
              </div>





              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(h) Do you suffer from any mental Disorders ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.mental}
                </div>
              </div>

              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(i Do you suffer from any communicable diseases ?</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.communicable}
                </div>
              </div>

              <div className="template__col14 template__col12 u-no-border-right">
                <div className="template__col14__headbox">
                  <span className="template__col14__head">(j) Have you had Yellow Fever Vaccination ?</span>
                  <span className="template__col14__required" style={{ display: 'none' }}>&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.yellowfever}
                </div>
              </div>



              <div className="template__col14 template__col12">
                <div className="template__col14__headbox">
                  <span className="template__col14__head" >(k) Date of Yellow Fever Vaccination</span>
                  <span className="template__col14__required">&#10059;</span>
                </div>
                <div className="template__col14__info">
                  {location.state.data.yellowfeverdate ? location.state.data.yellowfeverdate.toDateString() : ''}
                </div>
              </div>



            </div>

          </div>







          <div className="template__terms">

            <div className='template__terms__item'>

              I believe and declare that the information given in this application by me or on my behalf are true and that
              any documents provided in relation to this application are genuine to the best of my knowledge. I do
              consent to the processing of the information and documents according to Ghanaian law for the purposes of
              obtaining a Visa and to be held liable according to that law if any information or documents are found to be
              untruthful.
            </div>

          </div>
          <div className='template__terms__sign'>

            <div className="template__col14 template__col12">
              <div className="template__col14__headbox">
                <span className="template__col14__head">Date</span>
                <span className="template__col14__required" style={{ display: 'none' }}>&#10059;</span>
              </div>
              <div className="template__col14__info">
                &nbsp;
              </div>
            </div>

            <div className="template__col14 template__col12">
              <div className="template__col14__headbox">
                <span className="template__col14__head">Signature</span>
                <span className="template__col14__required" style={{ display: 'none' }}>&#10059;</span>
              </div>
              <div className="template__col14__info">
                &nbsp;
              </div>
            </div>
          </div>

          {/*
          
          <div className="template__declare">
            <div className="template__declare__text">
              I declare on this date <span className='template__declare__date'>{`${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`}</span> that the information given on this form whether entered by myself
              or on my behalf by a third party is true, complete and accurate, and all documents attached to this application are to the best of my knowledge and belief,
              genuine; and do consent to the processing of information in accordance with my application for a Visa/Entry Permit.
            </div>
            <div className='template__declare__sign' />
          </div>

          */}

          <div className="template__official">
            <h2 className='template__official__head'>Official Use Only</h2>
            <div className='template__official__box'>

              <div className="template__official__list">
                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Visa Application Number
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Type of Visa
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Visa Fee
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Payment Reference Number
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Visa Label Number
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Receipt Number
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Date of Issue
                  </div>
                  <div className="template__official__list__item__box" />
                </div>

                <div className="template__official__list__item">
                  <div className="template__official__list__item__label">
                    Issuing Officer
                  </div>
                  <div className="template__official__list__item__box" />
                </div>
              </div>

              <div className="template__official__passportbox" >
                <div className="template__official__passportitem">Passport pic</div>
              </div>
            </div>



          </div>

          <div className="template__footer">
            <p>Visa Application Form</p>
            <p>Page 2/2</p>
          </div>
        </div>
      </div>

      <div class="template__btns" style={showSpinner ? { display: 'none', width: '0' } : {}}>
        <button
          class="btn btn--normal"
          onClick={() => navigate('/', { replace: true, state: { formData: location.state.data } })}
        >
          &larr;Back
        </button>

        <button
          className="btn btn--normal"
          onClick={() => {
            // setShowSpinner(true)
            handleSubmit(location, setShowSpinner, navigate, setModalVisible)
            // exportPDF(location, setShowSpinner)
          }

          }
        >
          Submit &rarr;
        </button>
      </div>
      <Footer />

    </div>

  )

}



export default Template

import React, { Component } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Spinner from './shared/Spinner';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import DefaultPreview from '../img/undraw_my_files_swob.svg'
import { db, storage } from '../utils/firebase'
import { FaPassport } from 'react-icons/fa'
import { TbVaccine, TbSignature, TbCameraSelfie } from 'react-icons/tb'
import { IoIosPaper } from 'react-icons/io'
import { RiHotelFill } from 'react-icons/ri'
import { GiMoneyStack } from 'react-icons/gi'
import { BsFilePdf } from 'react-icons/bs'
import { useLocation, Navigate } from 'react-router-dom'
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import Rodal from 'rodal';
import UploadSuccess from '../img/phone.png';
import errorImg from '../img/delete.png';
import PDF from '../img/pdf.png';
import { isMobile } from 'react-device-detect'
import _ from 'lodash';
// include styles
import 'rodal/lib/rodal.css'
import emailjs from '@emailjs/browser';

let passportPicURL;
let passportURL;
let vaccinationCardURL;
let referenceURL;
let bookingURL;
let regformURL;
let visapayURL;
let othersURL;

let location = {};
let linkArray = [];
const UseLocation = () => {
    location = useLocation();
    // console.log(location.state)
    return null;
}

const unselected = [0, 1, 2, 3, 4, 5, 6, 7]

const guideElements = [
    (
        <div className="upload__guide__card__item">
            <TbCameraSelfie size={17} className="upload__guide__card__item__icon" />
            <span>Passport Size Photograph</span>

        </div>),
    (
        <div className="upload__guide__card__item">
            <FaPassport size={17} className="upload__guide__card__item__icon" />
            <span>Digital Passport</span>

        </div>),
    (

        <div className="upload__guide__card__item">
            <TbVaccine size={17} className="upload__guide__card__item__icon" />
            <span>Yellow Fever Vaccination Card</span>
        </div>),
    (
        <div className="upload__guide__card__item">
            <IoIosPaper size={17} className="upload__guide__card__item__icon" />
            <span>Reference Letter from Inviting Party</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <RiHotelFill size={17} className="upload__guide__card__item__icon" />
            <span>Booking Reservation</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <TbSignature size={17} className="upload__guide__card__item__icon" />
            <span>Signed Copy of Registration Form</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <GiMoneyStack size={17} className="upload__guide__card__item__icon" />
            <span>Evidence of Visa Payment</span>
        </div>
    ),
    (

        <div className="upload__guide__card__item">
            <BsFilePdf size={17} className="upload__guide__card__item__icon" />
            <span>Other Documents Not Listed</span>
        </div>
    )
]

export class UploadDocs extends Component {

    constructor(props) {

        super(props)
        this.PassportPicRef = React.createRef(null)
        this.PassportRef = React.createRef(null)
        this.VaccinationRef = React.createRef(null)
        this.ReferenceRef = React.createRef(null)
        this.BookingRef = React.createRef(null)
        this.RegformRef = React.createRef(null)
        this.VisapayRef = React.createRef(null)
        this.OthersRef = React.createRef(null)
    }

    componentDidMount() {
        // console.log('Inside DID MOUNT')
        // console.log(location)
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.passportPic !== this.state.passportPic) {
            if (this.state.passportPic !== null) {
                // console.log(this.state.passportPic)
                const fileType = this.state.passportPic.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)

                if (type[0] === 'image') {
                    passportPicURL = URL.createObjectURL(this.state.passportPic);
                    this.setState({ passportPicPreview: passportPicURL });

                    if (!this.state.selected.includes(0)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 0] })

                    }

                } else {

                    this.setState({ passportPic: '', passportPicPreview: '', errorModalmessage: 'IMAGE' }, () =>
                        this.setState({ errorModalVisible: true })
                    )

                }

                return;
            }

        }

        if (prevState.passport !== this.state.passport) {
            if (this.state.passport !== null) {
                passportURL = URL.createObjectURL(this.state.passport);
                this.setState({ passportPreview: passportURL });

                if (!this.state.selected.includes(1)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 1] })

                }
                return;
            }

        }

        if (prevState.vaccinationcard !== this.state.vaccinationcard) {
            if (this.state.vaccinationcard != null) {
                vaccinationCardURL = URL.createObjectURL(this.state.vaccinationcard);
                this.setState({ vaccinationcardPreview: vaccinationCardURL })

                if (!this.state.selected.includes(2)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 2] })

                }
                return;
            }
        }

        if (prevState.referenceletter !== this.state.referenceletter) {
            if (this.state.referenceletter !== null) {
                referenceURL = URL.createObjectURL(this.state.referenceletter);
                this.setState({ referenceletterPreview: referenceURL })

                if (!this.state.selected.includes(3)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 3] })

                }
                return;
            }
        }

        if (prevState.booking !== this.state.booking) {
            if (this.state.booking !== null) {
                bookingURL = URL.createObjectURL(this.state.booking);
                this.setState({ bookingPreview: bookingURL })

                if (!this.state.selected.includes(4)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 4] })

                }
                return;
            }
        }

        if (prevState.registrationform !== this.state.registrationform) {
            if (this.state.registrationform !== null) {
                const fileType = this.state.registrationform.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)
                if (type[1] === 'pdf') {
                    regformURL = URL.createObjectURL(this.state.registrationform);
                    this.setState({ registrationformPreview: regformURL })

                    if (!this.state.selected.includes(5)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 5] })

                    }

                } else {
                    this.setState({ registrationform: '', registrationformPreview: '', errorModalmessage: 'PDF' }, () =>
                        this.setState({ errorModalVisible: true }))

                }



                return;
            }
        }

        if (prevState.visapay !== this.state.visapay) {
            if (this.state.visapay !== null) {
                visapayURL = URL.createObjectURL(this.state.visapay);
                this.setState({ visapayPreview: visapayURL })

                if (!this.state.selected.includes(6)) {
                    // console.log('Inlucde guide')
                    this.setState({ selected: [...this.state.selected, 6] })

                }
                return;
            }
        }

        if (prevState.others !== this.state.others) {
            if (this.state.others !== null) {
                const fileType = this.state.others.type;
                // console.log(fileType)
                const type = fileType === undefined ? '' : fileType.split('/')
                // console.log(type)
                if (type[1] === 'pdf') {
                    othersURL = URL.createObjectURL(this.state.others);
                    this.setState({ othersPreview: othersURL })

                    if (!this.state.selected.includes(7)) {
                        // console.log('Inlucde guide')
                        this.setState({ selected: [...this.state.selected, 7] })

                    }

                } else {
                    this.setState({ others: '', othersPreview: '', errorModalmessage: 'PDF' }, () =>
                        this.setState({ errorModalVisible: true }))

                }



                return;
            }
        }
    }

    componentWillUnmount() {
        URL.revokeObjectURL(passportPicURL)
        URL.revokeObjectURL(passportURL)
        URL.revokeObjectURL(vaccinationCardURL)
        URL.revokeObjectURL(referenceURL)
        URL.revokeObjectURL(bookingURL)
        URL.revokeObjectURL(regformURL)
        URL.revokeObjectURL(visapayURL)
        URL.revokeObjectURL(othersURL)
    }

    state = {
        passportPic: '',
        passport: '',
        vaccinationcard: '',
        referenceletter: '',
        booking: '',
        registrationform: '',
        visapay: '',
        others: '',

        passportPicPreview: '',
        passportPreview: '',
        vaccinationcardPreview: '',
        referenceletterPreview: '',
        bookingPreview: '',
        registrationformPreview: '',
        visapayPreview: '',
        othersPreview: '',


        selected: [],
        uploadedStatus: false,
        modalVisible: false,
        errorModalVisible: false,
        errorModalmessage: '',

        showSpinner: false,
        navigate: false,
    }

    handleChange = (e) => {
        e.preventDefault()
        const value = e.target.files[0]
        // // console.log(value)

        if (value === undefined) {
            return
        } else {
            this.setState({
                ...this.state,
                [e.target.id]: value,

            }, () => {
                // console.log(this.state)
            })
        }



    }

    /*

    handleSubmitDocs = async () => {
        let linkArray = [];
        let percent;
        const data = location.state;
        // console.log(data)
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others } = this.state;
        // console.log('over here')
        const allSelected = passport !== '' && passportPic !== '' && vaccinationcard !== '' && referenceletter !== '' && booking !== '' && registrationform !== '' && visapay !== '';
        // console.log(allSelected)
        this.setState({
            showSpinner: true,
        },

            () => {

                if (allSelected) {

                    let storageRef = ref(storage, `${'passportsizepic'}/${data.data.ID}${passportPic.name}`)
                    let uploadTask = uploadBytesResumable(storageRef, passportPic);

                    uploadTask.on(
                        'state_changed', (snapshot) => {
                            percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );

                            // console.log(percent)
                        }, (error) => // console.log(error),
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                // console.log('passport link -> ' + url)
                                linkArray.push(url)

                                // UPLOAD PASSPORT

                                storageRef = ref(storage, `${'passport'}/${data.data.ID}${passport.name}`);

                                uploadTask = uploadBytesResumable(storageRef, passport);

                                uploadTask.on(
                                    'state_changed', (snapshot) => {
                                        percent = Math.round(
                                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                        );

                                        // console.log(percent)
                                    }, (error) => // console.log(error),
                                    () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                            // console.log('Visa Payment  link -> ' + url)
                                            linkArray.push(url)
                                            // console.log(linkArray)
                                            // console.log('done')

                                            //  ADD LINKS OF FILES TO DATABASE


                                            this.setLinks(linkArray)

                                            // UPLOAD VACCINATION CARD NOW
                                            storageRef = ref(storage, `${'vaccine'}/${data.data.ID}${vaccinationcard.name}`)
                                            uploadTask = uploadBytesResumable(storageRef, vaccinationcard);



                                            uploadTask.on(
                                                'state_changed', (snapshot) => {
                                                    percent = Math.round(
                                                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                    );

                                                    // console.log(percent)
                                                }, (error) => // console.log(error),
                                                () => {
                                                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                        // console.log('vaccination link -> ' + url)
                                                        linkArray.push(url)

                                                        // UPLOAD REFERENCE LETTER NOW
                                                        storageRef = ref(storage, `${'referenceletter'}/$${data.data.ID}${referenceletter.name}`)
                                                        uploadTask = uploadBytesResumable(storageRef, referenceletter);



                                                        uploadTask.on(
                                                            'state_changed', (snapshot) => {
                                                                percent = Math.round(
                                                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                );

                                                                // console.log(percent)
                                                            }, (error) => // console.log(error),
                                                            () => {
                                                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                    // console.log('Reference letter link -> ' + url)
                                                                    linkArray.push(url)

                                                                    // UPLOAD BOOKING CARD NOW
                                                                    storageRef = ref(storage, `${'booking'}/$${data.data.ID}${booking.name}`)
                                                                    uploadTask = uploadBytesResumable(storageRef, booking);



                                                                    uploadTask.on(
                                                                        'state_changed', (snapshot) => {
                                                                            percent = Math.round(
                                                                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                            );

                                                                            // console.log(percent)
                                                                        }, (error) => // console.log(error),
                                                                        () => {
                                                                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                                // console.log('Booking link -> ' + url)
                                                                                linkArray.push(url)

                                                                                // UPLOAD SIGNED FORM NOW
                                                                                storageRef = ref(storage, `${'signedform'}/${data.data.ID}$${registrationform.name}`)
                                                                                uploadTask = uploadBytesResumable(storageRef, registrationform);


                                                                                uploadTask.on(
                                                                                    'state_changed', (snapshot) => {
                                                                                        percent = Math.round(
                                                                                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                                        );

                                                                                        // console.log(percent)
                                                                                    }, (error) => // console.log(error),
                                                                                    () => {
                                                                                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                                            // console.log('Signed Form link -> ' + url)
                                                                                            linkArray.push(url)



                                                                                            // UPLOAD  VISA PAYMENT NOW
                                                                                            storageRef = ref(storage, `${'visapayment'}/$${data.data.ID}${visapay.name}`)
                                                                                            uploadTask = uploadBytesResumable(storageRef, visapay);


                                                                                            uploadTask.on(
                                                                                                'state_changed', (snapshot) => {
                                                                                                    percent = Math.round(
                                                                                                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                                                    );

                                                                                                    // console.log(percent)
                                                                                                }, (error) => // console.log(error),
                                                                                                () => {
                                                                                                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                                                        // console.log('Visa Payment  link -> ' + url)
                                                                                                        linkArray.push(url)
                                                                                                        // console.log(linkArray)


                                                                                                        // UPLOAD OTHER DOCS
                                                                                                        if (others === '') {

                                                                                                            linkArray.push('NONE')
                                                                                                            this.setLinks(linkArray)
                                                                                                        } else {

                                                                                                            storageRef = ref(storage, `${'otherdocs'}/$${data.data.ID}${others.name}`)
                                                                                                            uploadTask = uploadBytesResumable(storageRef, others);

                                                                                                            uploadTask.on(
                                                                                                                'state_changed', (snapshot) => {
                                                                                                                    percent = Math.round(
                                                                                                                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                                                                    );

                                                                                                                    // console.log(percent)
                                                                                                                }, (error) => // console.log(error),
                                                                                                                () => {
                                                                                                                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                                                                        // console.log('Visa Payment  link -> ' + url)
                                                                                                                        linkArray.push(url)
                                                                                                                        // console.log(linkArray)


                                                                                                                        // UPLOAD OTHER DOCS
                                                                                                                        storageRef = ref(storage, `${'otherdocs'}/$${data.data.ID}${others.name}`)
                                                                                                                        uploadTask = uploadBytesResumable(storageRef, others);

                                                                                                                        uploadTask.on(
                                                                                                                            'state_changed', (snapshot) => {
                                                                                                                                percent = Math.round(
                                                                                                                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                                                                                                );

                                                                                                                                // console.log(percent)
                                                                                                                            }, (error) => // console.log(error),
                                                                                                                            () => {
                                                                                                                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                                                                                                                    // console.log('Other Docs  link -> ' + url)
                                                                                                                                    linkArray.push(url)
                                                                                                                                    // console.log(linkArray)


                                                                                                                                    //  ADD LINKS OF FILES TO DATABASE
                                                                                                                                    this.setLinks(linkArray)




                                                                                                                                })
                                                                                                                            }
                                                                                                                        )


                                                                                                                    })
                                                                                                                }
                                                                                                            )

                                                                                                        }


                                                                                                    })
                                                                                                }
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                )

                                                                            })
                                                                        }
                                                                    )


                                                                })
                                                            }
                                                        )



                                                    })
                                                })




                                        })
                                    }
                                )

                            })
                        }
                    )


                } else {
                    this.setState({
                        showSpinner: false,
                    });
                    alert('Please Select All Documents Before Submitting')

                }
            })


    }
    */

    fireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
        x.seconds * 1000 + x.nanoseconds / 1000000,
    ).toDateString();

    _handleSubmit = async () => {

        this.setState({
            showSpinner: true,
        })

        const startIndex = 0;

        // console.log(data)
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others } = this.state;
        // console.log('over here')
        const compulsoryCheck = passport !== '' && passportPic !== '' && vaccinationcard !== '' && registrationform !== '' && visapay !== '';
        // console.log(allSelected)
        const uploadArray = [
            {
                path: 'passportsizepic',
                file: passportPic,
            },
            {
                path: 'passport',
                file: passport

            },
            {
                path: 'vaccine',
                file: vaccinationcard

            },
            {
                path: 'referenceletter',
                file: referenceletter

            },
            {
                path: 'booking',
                file: booking

            },
            {
                path: 'signedform',
                file: registrationform

            },
            {
                path: 'visapayment',
                file: visapay

            },
            {
                path: 'otherdocs',
                file: others

            },


        ]


        if (compulsoryCheck) {
            this.uploadDoc(uploadArray, startIndex)
            // console.log('worked -hard')


        } else {
            this.setState({
                showSpinner: false,
            });
            alert('Please Select All Mandatory Documents Before Submitting')
        }


    }



    uploadDoc = (uploadData, index) => {
        console.log(index);

        if (index < uploadData.length) {

            if (uploadData[index].file === '') {
                // console.log('Index' + index + 'is empty');
                linkArray.push('NONE');
                this.uploadDoc(uploadData, ++index)

            } else {
                // console.log('uploading')
                const data = location.state;
                let percent;

                let storageRef = ref(storage, `${uploadData[index].path}/${data.data.ID}${uploadData[index].file.name}`)
                let uploadTask = uploadBytesResumable(storageRef, uploadData[index].file);



                uploadTask.on(
                    'state_changed', (snapshot) => {
                        percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // console.log(percent)
                    }, (error) => console.log(error),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // console.log('passport link -> ' + url)
                            linkArray.push(url)

                            // UPLOAD PASSPORT

                            this.uploadDoc(uploadData, ++index)


                        })
                    }
                )

            }



        } else {
            //  ADD LINKS OF FILES TO DATABASE
            this.setLinks(linkArray)



        }



    }

    setLinks = async (linkArray) => {
        const applicantRef = doc(db, 'applications', location.state.id)
        try {
            await updateDoc(applicantRef, {
                Link_To_Files: JSON.stringify(linkArray),
                STATUS: 1,
                passwordUsed: true
            });

            this.notifyAdmins(linkArray)

        } catch (e) {
            // console.log(e)
        }

    }

    checkLinkArrayLength(linkArray) {
        if (linkArray.length < 8) {
            // console.log('Check ongoing')
            return false
        } else {
            // console.log('Check passed')
            return true
        }

    }



    notifyAdmins(linkArray) {
        const { passport, passportPic, booking, visapay, vaccinationcard, referenceletter, registrationform, others } = this.state

        if (this.checkLinkArrayLength(linkArray)) {
            // console.log('Check Complete')

            const mailParams = {
                name: `${location.state.data.Firstnames} ${location.state.data.Surname}`,
                applicationDate: this.fireBaseTime(location.state.data.created),
                to_email: 'nanaotu@gmail.com',

                passportPic: passportPic === '' ? 'No' : 'Yes',
                passport: passport === '' ? 'No' : 'Yes',
                booking: booking === '' ? 'No' : 'Yes',
                visapay: visapay === '' ? 'No' : 'Yes',
                vaccinationcard: vaccinationcard === '' ? 'No' : 'Yes',
                referenceletter: referenceletter === '' ? 'No' : 'Yes',
                registrationform: registrationform === '' ? 'No' : 'Yes',
                others: others === '' ? 'No' : 'Yes',
            }

            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_9cw33b9', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {

                alert('Admin Notified')
                // console.log('Admins Notified')


                this.sendClientEmail()
                
            }, (error) => {

                // console.log('Admins email NOT sent')
                // console.log(error)
                this.setState({
                    uploadedStatus: true,


                    showSpinner: false,

                }, () => {
                    // alert('All Documents uploaded to Server')
                    this.setState({ modalVisible: true })
                })
            })
        } else {
            // console.log('Rechecking')
            this.checkLinkArrayLength(linkArray)
        }


    }

    sendClientEmail() {

        const mailParams = {
            to_name: `${location.state.data.Firstnames} ${location.state.data.Surname}`,
            to_email: location.state.data.Email,
            to_ref: location.state.data.ID,
        }

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_s8funon', mailParams, process.env.REACT_APP_EMAILJS_USER_ID).then(response => {

            // alert('Client Notified')
            // console.log('Client Notified')


            this.setState({
                uploadedStatus: true,


                /*
                passportPreview: '',
                vaccinationcardPreview: '',
                referenceletterPreview: '',
                bookingPreview: '',
                registrationformPreview: '',
                visapayPreview: '', passport: '',
                vaccinationcard: '',
                referenceletter: '',
                booking: '',
                registrationform: '',
                visapay: '',
                */


                showSpinner: false,

            }, () => {

                this.setState({ modalVisible: true })

            })
            
        }, (error) => {

            // console.log('Client email NOT sent')
            // console.log(error)
            this.setState({
                uploadedStatus: true,


                showSpinner: false,

            }, () => {
                this.setState({ modalVisible: true })
            })
        })


    }



    handleRodalClose() {
        this.setState({ modalVisible: false, navigate: true },)
    }



    render() {
        const { passportPic, passport, vaccinationcard, referenceletter, booking, registrationform, visapay, others, showSpinner, selected, navigate } = this.state;

        return (
            <div className='upload'>
                <UseLocation />
                {navigate && <Navigate to={`/login/${location.state.link}`} />}
                <Header />

                <Spinner showSpinner={showSpinner} />

                <Rodal
                    visible={this.state.modalVisible}
                    width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
                    height={440}
                >
                    <div className='myrodal'>
                        <div className="myrodal__iconbox">
                            <img src={UploadSuccess} alt="File upload sucesss" className='myrodal__icon' />
                        </div>
                        <div className="myrodal__message">

                            Thank you for uploading your documentation. The Consulate will review your application and respond
                            to by phone or email on the status of your application.

                        </div>

                        <div className="myrodal__btnContainer" onClick={() => this.handleRodalClose()}>
                            <button className="btn btn--normal">Ok</button>
                        </div>

                    </div>
                </Rodal>

                <Rodal
                    visible={this.state.errorModalVisible}
                    width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
                    height={350}
                >
                    <div className='myrodal'>
                        <div className="myrodal__iconbox">
                            <img src={errorImg} alt="File upload sucesss" className='myrodal__icon' />
                        </div>
                        <div className="myrodal__message" style={{ fontWeight: 'bold' }}>

                            kindly Select File of type <span style={{ color: 'red' }}>{this.state.errorModalmessage}</span><br />
                            Thank You

                        </div>

                        <div className="myrodal__btnContainer" onClick={() => this.setState({ errorModalVisible: false })}>
                            <button className="btn btn--normal">Ok</button>
                        </div>

                    </div>
                </Rodal>

                <div className="upload__container" style={showSpinner ? { display: 'none', width: '0' } : {}}>
                    <div className='upload__container__box'>
                        <div className="upload__guide">

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Uploaded</p>
                                {
                                    !this.state.uploadedStatus ?
                                        <p className='upload__guide__card__nofile'>No Files Uploaded</p>
                                        :
                                        unselected.map((item) => (
                                            guideElements[item]
                                        ))

                                }


                            </div>

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Selected</p>

                                {selected.length === 0 ? (
                                    <p className='upload__guide__card__nofile'>No Files Selected</p>

                                ) : (
                                    selected.map((item) => (
                                        guideElements[item]

                                    )
                                    ))}

                            </div>

                            <div className="upload__guide__card">
                                <p className="upload__guide__card__head">Not Selected</p>

                                {selected.length === 8 ?
                                    (<p className='upload__guide__card__nofile'>All files Selected</p>)
                                    : unselected.filter(x => !selected.includes(x)).map((item) => (
                                        guideElements[item]
                                    ))
                                }

                            </div>

                        </div>

                        <div className="upload__main">


                            <div className="upload__main__card">
                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={this.state.passportPicPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{passportPic !== "" ? passportPic.name.split('.')[0] : 'Passport Size Photo'}</p>
                                        <p className="upload__main__card__textbox__item--type">{passportPic !== "" ? passportPic.type : 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{passportPic !== "" ? `${Math.round(passportPic.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Passport Size Photo</p>
                                        <p className="upload__main__card__textbox__item--priority">Mandatory</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="passportPic"
                                        name="passportPic"
                                        ref={this.PassportPicRef}
                                        type="file"
                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            // console.log('heldj')
                                            this.PassportPicRef.current.click()
                                        }}
                                    >
                                        {this.state.passportPic === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">
                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={this.state.passportPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{passport.name ? passport.name.split('.')[0] : 'Passport Biodata'}</p>
                                        <p className="upload__main__card__textbox__item--type">{passport.type || 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{passport.size ? `${Math.round(passport.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Passport Biodata</p>
                                        <p className="upload__main__card__textbox__item--priority">Mandatory</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="passport"
                                        name="passport"
                                        ref={this.PassportRef}
                                        type="file"
                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            // console.log('heldj')
                                            this.PassportRef.current.click()
                                        }}
                                    >
                                        {this.state.passport === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">


                                        <img src={this.state.vaccinationcardPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />

                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{vaccinationcard ? vaccinationcard.name.split('.')[0] : 'Vaccination Card'}</p>
                                        <p className="upload__main__card__textbox__item--type">{vaccinationcard ? vaccinationcard.type : "No Type"}</p>

                                        <p className="upload__main__card__textbox__item--size">{vaccinationcard ? `${Math.round(vaccinationcard.size / 1000)} kb` : "No size"}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Vaccination Card</p>
                                        <p className="upload__main__card__textbox__item--priority">Mandatory</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="vaccinationcard"
                                        name="vaccinationcard"
                                        ref={this.VaccinationRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.VaccinationRef.current.click()
                                        }}
                                    >
                                        {this.state.vaccinationcard === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={this.state.referenceletterPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{referenceletter.name ? referenceletter.name.split('.')[0] : 'Reference / Inviting Letter'}</p>
                                        <p className="upload__main__card__textbox__item--type">{referenceletter.type || 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{referenceletter.size ? `${Math.round(referenceletter.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Reference / Inviting Letter</p>
                                        <p className="upload__main__card__textbox__item--priority">Optional</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="referenceletter"
                                        name="referenceletter"
                                        ref={this.ReferenceRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {
                                            this.ReferenceRef.current.click()
                                        }}
                                    >
                                        {this.state.referenceletter === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={this.state.bookingPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{booking.name ? booking.name.split('.')[0] : 'Hotel Reservation'}</p>
                                        <p className="upload__main__card__textbox__item--type">{booking.type || 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{booking.size ? `${Math.round(booking.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Hotel Reservation</p>
                                        <p className="upload__main__card__textbox__item--priority">Optional</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="booking"
                                        name="booking"
                                        ref={this.BookingRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.BookingRef.current.click()
                                        }}
                                    >
                                        {this.state.booking === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={PDF} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{registrationform.name ? registrationform.name.split('.')[0] : 'Signed Application Form'}</p>
                                        <p className="upload__main__card__textbox__item--type">{registrationform.type || 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{registrationform.size ? `${Math.round(registrationform.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Signed Application Form</p>
                                        <p className="upload__main__card__textbox__item--priority">Mandatory</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="registrationform"
                                        name="registrationform"
                                        ref={this.RegformRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.RegformRef.current.click()
                                        }}
                                    >
                                        {this.state.registrationform === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>
                            </div>


                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={this.state.visapayPreview || DefaultPreview} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{visapay.name ? visapay.name.split('.')[0] : 'Visa Payment Evidence'}</p>
                                        <p className="upload__main__card__textbox__item--type">{visapay.type || 'No Type'}</p>
                                        <p className="upload__main__card__textbox__item--size">{visapay.size ? `${Math.round(visapay.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Visa payment Evidence</p>
                                        <p className="upload__main__card__textbox__item--priority">Mandatory</p>

                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="visapay"
                                        name="visapay"
                                        ref={this.VisapayRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.VisapayRef.current.click()
                                        }}
                                    >
                                        {this.state.visapay === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                            <div className="upload__main__card">

                                <div className="upload__main__card__imgtextbox">
                                    <div className="upload__main__card__imgbox">
                                        <img src={PDF} alt="Preview of uploaded file" className="upload__main__card__img" />
                                    </div>
                                    <div className="upload__main__card__textbox">
                                        <p className="upload__main__card__textbox__item--name">{others.name ? others.name.split('.')[0] : 'Other Documents'}</p>
                                        <p className="upload__main__card__textbox__item--type">{others.type || 'No Type'}</p>

                                        <p className="upload__main__card__textbox__item--size">{others.size ? `${Math.round(others.size / 1000)} kb` : 'No Size'}</p>
                                        <p className="upload__main__card__textbox__item--dimensions">Other Documents Not Listed</p>
                                        <p className="upload__main__card__textbox__item--priority">Optional</p>
                                    </div>
                                </div>
                                <div className="upload__main__card__btncontainer">
                                    <input

                                        id="others"
                                        name="others"
                                        ref={this.OthersRef}
                                        type="file"

                                        multiple={false}
                                        onChange={this.handleChange.bind(this)}
                                        className="upload__main__card__input"
                                    />

                                    <button
                                        className='btn upload__main__card__btn'
                                        onClick={() => {

                                            this.OthersRef.current.click()
                                        }}
                                    >
                                        {this.state.others === '' ? 'Upload' : 'Update'}
                                    </button>

                                </div>

                            </div>

                        </div>


                    </div>
                    <div className="upload__submitcontainer" onClick={() => this._handleSubmit()}>
                        <button className='btn btn--normal'>Submit </button>
                    </div>


                </div>

                <Footer />
            </div>
        )
    }
}

export default UploadDocs
export const IndexToName = [
    {
        id: 'firstname',
        title: 'firstname'
    }, 
    {
        id: 'othername',
        title: 'othername(S)'
    }, 
    {
        id: 'surname',
        title: 'surname',
    }, 
    {
        id: 'dob',
        title: 'date of birth', 
    }, 
    {
        id: 'pob',
        title: 'place of birth',
    }, 
    {
        id: 'cob',
        title: 'country of birth',
    }, 
   
    {
        id: 'contact',
        title:  'contact',
    }, 
    {
        id: 'occupation',
        title: 'occupation',
    }, 
    {
        id: 'residentialaddress',
        title: 'Full Residential Address',
    },  

    {
        id: 'isastudent',
        title: 'ARE YOU A STUDENT',
    },  

    {
        id: 'academic',
        title: 'NAME OF ACADEMIC INSTITUITION',
    },  

    {
        id: 'employer',
        title: 'NAME OF EMPLOYER / PREVIOUS EMPLOYER',
    },  

    {
        id: 'email',
        title: 'Email',
    },  
 
        

    {
        id: 'confirmemail',
        title: 'Confirm Email',
    },  
       


    {
        id: 'visatype',
        title: 'VISA REQUIRED',
    },  

    {
        id: 'paymethod',
        title: 'METHOD OF PAYMENT',
    },  

    {
        id: 'previousvisit',
        title: 'PREVIOUSLY VISITED GHANA',
    },  

    {
        id: 'dateofvisit',
        title: 'DATE OF LAST VISIT',
    },  

    {
        id: 'nationality',
        title: 'RAVEL PASSPORT NATIONALITY',
    },  

    {
        id: 'passporttype',
        title: 'PASSPORT TYPE',
    }, 
    
    {
        id: 'passportno',
        title: 'PASSPORT NUMBER',
    }, 

    {
        id: 'issuedat',
        title: 'PASSPORT ISSUED AT',
    },  

    {
        id: 'passportexpiry',
        title: 'PASSPORT EXPIRY',
    },  

    {
        id: 'othernationality',
        title: 'OTHER NATIONALITY',
    },  

    {
        id: 'othernationality2',
        title: 'OTHER NATIONALITY 2',
    },  

    {
        id: 'purpose',
        title: 'PURPOSE OF TRIP',
    },


    {
        id: 'dateofarrival',
        title: 'EXPECTED ARRIVAL DATE',
    },

        
    {
        id: 'otherpurpose',
        title: 'IF OTHER (PLEASE STATE) FOR PURPOSE OF TRIP',
    },  

    {
        id: 'fundingsource',
        title: 'SOURCE OF FUNDING',
    },  

    {
        id: 'modeoftravel',
        title: 'MPDE OF TRAVEL',
    }, 
    {
        id: 'hostfullname',
        title: 'NAME & CONTACT OF PERSON IN GHANA FULLNAME',
    },  

    {
        id: 'hostaddress',
        title: 'NAME & CONTACT OF PERSON IN GHANA ADDRESS',
    },  

    {
        id: 'hostcountry',
        title: 'NAME & CONTACT OF PERSON IN GHANA COUNTRY',
    }, 
        
    {
        id: 'hostcontact',
        title: 'NAME & CONTACT OF PERSON IN GHANA CONTACT',
    },  

    {
        id: 'hotelfullname',
        title: 'ADDRESS/HOTEL WHERE APPLICANT WILL BE STAYING FULLNAME',
    },  

    {
        id: 'hoteladdress',
        title: 'ADDRESS/HOTEL WHERE APPLICANT WILL BE STAYING ADDRESS',
    }, 
        
    {
        id: 'hotelcountry',
        title:  'ADDRESS/HOTEL WHERE APPLICANT WILL BE STAYING COUNTRY',
    },  

    {
        id: 'hotelcontact',
        title: 'ADDRESS/HOTEL WHERE APPLICANT WILL BE STAYING CONTACT',
    },           

    {
        id: 'nextofkinfullname',
        title: 'NAME & CONTACT OF NEXT-OF-KIN FULLNAME',
    }, 
        
    {
        id: 'nextofkinaddress',
        title: 'NAME & CONTACT OF NEXT-OF-KIN ADDRESS',
    },  

    {
        id: 'nextofkincountry',
        title: 'NAME & CONTACT OF NEXT-OF-KIN COUNTRY',
    },  

    {
        id: 'nextofkincontact',
        title: 'NAME & CONTACT OF NEXT-OF-KIN CONTACT',
    }, 
    {
        id: 'deportedfromgh',
        title: 'EVER BEEN REFUSED ENTRY TO / DEPORTED FROM GHANA ?',
    }, 
        
    {
        id: 'deportedfromghdate',
        title: 'IF YES, STATE DATE AND REASON(S) FOR EVER BEEN REFUSED ENTRY TO / DEPORTED FROM GHANA ?',
    },  

    {
        id: 'deportedfromghreason',
        title:  'IF YES, STATE DATE AND REASON(S) FOR EVER BEEN REFUSED ENTRY TO / DEPORTED FROM GHANA ?',
    },  

    {
        id: 'deported',
        title: 'EVER BEEN REFUSED ENTRY TO / DEPORTED FROM ANY COUNTRY ?', 
    }, 
    {
        id: 'deportedcountry',
        title: 'IF YES, STATE COUNTRY, DATE AND REASON(S) FOR EVER BEEN REFUSED ENTRY TO / DEPORTED FROM ANY COUNTRY ?',
    }, 
        
    {
        id: 'deporteddate',
        title: 'IF YES, STATE COUNTRY, DATE AND REASON(S) FOR EVER BEEN REFUSED ENTRY TO / DEPORTED FROM ANY COUNTRY ?',
    },  

    {
        id: 'deportedreason',
        title: 'IF YES, STATE COUNTRY, DATE AND REASON(S) FOR EVER BEEN REFUSED ENTRY TO / DEPORTED FROM ANY COUNTRY ?',
    },  

    {
        id: 'criminal',
        title: 'DO YOU HAVE A CRIMINAL RECORD IN ANY COUNTRY ?',
    }, 

    {
        id: 'mental',
        title:  'DO YOU SUFFER FROM ANY MENTAL DISORDERS ?',
    },  

    {
        id: 'communicable',
        title: 'DO YOU SUFFER FROM ANY COMMUNICABLE DISEASES ?',
    }, 
    {
        id: 'yellowfever',
        title: 'HAVE YOU HAD YELLOW FEVER VACCINATION ?',
    }, 
        
    {
        id: 'yellowfeverdate',
        title: 'IF YES, STATE DATE OF VACCINATION',
    },  
  
]

// THE NUMBERS ARE INDEXES FOR THE INPUTS ACCORDING TO THE INDEX TO NAME ARRAY
export const outlineBorderInputs = [9, 16, 42, 45, 49, 50, 51, 52]

// INDEXES OF ALL DEPENDENT INPUTS AND TRULY NOT REQUIRED INPUTS SUCH AS(25, 26 othernationality) ACCORDING TO THE INDEX TO NAME ARRAY. 
// THE TRIGGEREDREQUIRED WOULD BE FILTERED FROM THIS TO FIND THE ACTUAL UNREQUIRED FIELDS
export const allDependentInputs = [1, 10, 11, 17, 23, 24, 27, 30, 31, 32, 33, 34, 35, 36, 37,  43, 44, 46, 47, 48, 53]

// INDEX OF THE MASTER CONTROL INPUTS AND THEIR DEPENDENT CONTROLS INDEXES
// PURPOSE OF TRAVEL HAS SPECIFIC VALUES AND THE FIELDS BECOME DEPENDENT BASED ON THE VALUE IN THERE, SO WE HANDLE THAT SEPARATELY
// if other is selected in purpose of travel, the please state reason for other is reqquired which is different from the other dependent fields like Name of host, Address of hotel
export const masterControls = [
    {
        id: 'isastudent',
        master: 9,
        controls: [10, 11]
    },
    {
        id: 'previousvisit',
        master: 16,
        controls: [17],
    },
    {
        id: 'purpose',
        master:  25,
        controls: [27],
    },
    // Purpose controls Name of host and Address of hotel in Ghana but it is handled at the form component function handlePurposeOfVisit

    {
        id: 'deportedfromgh',
        master:  42,
        controls: [43, 44],
    },
    {
        id: 'deported',
        master:  45,
        controls: [46, 47, 48],
    },
    {
        id: 'yellowfever',
        master:  52,
        controls: [53],
    },

]

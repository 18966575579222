import React, { Component } from 'react'

import Spinner from './shared/Spinner';
import { Navigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import _ from 'lodash';
import Header from './shared/Header';
import Footer from './shared/Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CountryDropdown } from 'react-country-region-selector';
import CountryData from './shared/countries.json';
import { IndexToName, outlineBorderInputs, masterControls } from './shared/input_index_names';
import { db } from '../utils/firebase';
import { collection, getDocs } from "firebase/firestore";
import Flag from '../img/flag.png';
import YearDatePicker from 'react-date-picker';
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import { isMobile } from 'react-device-detect';
import Avatar from '../img/ferret.png'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LogicModal from './shared/LogicModal';
import { ordinaryPassportPass, diplomaticPassportPass, servicePassportPass, officialPassportPass } from './shared/countryPass';

let location;

const UseLocation = () => {
    location = useLocation()
    // console.log(location.state)
    // console.log(_.isEmpty(location.state))
    return null
}

let triggeredRequired = [];
let utils;
let feesArray = [];
let applicationArray = [];

export class Form extends Component {

    constructor(props) {
        super(props)
        this.validateArrivalDate = this.validateArrivalDate.bind(this);
        this.fetchFormData();

    }



    state = {
        // USER ID
        ID: '',
        accesscode: '',

        //  TRAVELLING INFORMATION
        visatype: '',
        purpose: '',
        nationality: '',



        passporttype: '',
        passportno: '',
        issuedat: '',
        passportexpiry: '',
        othernationality: '',




        othernationality2: '',

        otherpurpose: '',
        modeoftravel: '',

        hostfullname: '',
        hostaddress: '',
        hostcontact: '',
        hostcountry: 'Ghana',

        hotelfullname: '',
        hoteladdress: '',
        hotelcountry: 'Ghana',
        hotelcontact: '',

        nextofkinfullname: '',
        nextofkinaddress: '',
        nextofkincountry: 'Bermuda',
        nextofkincontact: '',




        paymethod: '',
        fundingsource: '',
        previousvisit: '',
        dateofvisit: '',
        dateofarrival: '',
        // dateofarrival: new Date(),


        // hostid: '',
        // hotelid: '',
        // nextofkin: '',

        // PERSONAL INFORMATION

        firstname: '',
        othername: '',
        surname: '',
        // dob: new Date(),

        dob: '', // date of birth
        pob: '',   // place of birth
        cob: '',    // country of birth

        contact: '',
        occupation: '',
        residentialaddress: '',

        employer: '',
        academic: '',
        isastudent: '',

        email: '',
        confirmemail: '',


        // DECLARATIONS
        deported: '',

        deportedfromgh: '',

        deportedfromghdate: '',
        deportedfromghreason: '',

        deportedcountry: '',
        deporteddate: '',
        deportedreason: '',

        criminal: '',
        mental: '',
        communicable: '',
        yellowfever: '',
        yellowfeverdate: '',


        // FORM CONTROLS
        pageIndex: 0,
        selectedCountry: {
            "name": "Bermuda",
            "dialCode": "+1441",
            "isoCode": "BM"
        },

        // To store indexes of all dependent inputs that have been made required because their control is set to a particular value
        requiredDependentInputs: [],


        // SPINNER
        // showSpinner: true,
        showSpinner: true,

        // NAVIGATE
        navigate: false,

        //  Retrieve Modal
        _visible: false,
        _message: `Hello User, IS This Your First Time Completing This Form ? 
        If NO, then click the OK button to reload your old data for editing or Close the Popup to continue filling the form`,

        matchIndex: -1,
        matchID: '',


        // LOGIC MODAL
        _logicModalVisible: false,
        _logicModalMessage: 'Please download, complete and upload a Guardian consent form.', // Initial message set to first logic
        _logicIndex: 0, // Selects the current logic being worked to find the function to execute


    }


    fetchFormData = async () => {

        feesArray = [];
        applicationArray = [];

        const querySnapshot = await getDocs(collection(db, "utils"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            utils = doc.data()
            // console.log(doc.id, " => ", doc.data());
        });
        // console.log(utils);

        feesArray = JSON.parse(utils.ALL_FEES)

        const showNotif = utils.SHOW_NOTIF;
        const notifBody = utils.APP_NOTIF;

        // console.log(showNotif)
        // console.log(notifBody)

        const appQuerySnapshot = await getDocs(collection(db, "applications"));
        applicationArray = [];
        appQuerySnapshot.forEach((doc) => {
            applicationArray.push({ id: doc.id, data: doc.data() })
        })

        // console.log(applicationArray);

        // Test it out and fetch form data

        if (showNotif) {
            this.setState({
                _logicModalMessage: notifBody,
                _logicIndex: 1,
                _logicModalVisible: true
            })
        }




        setTimeout(() => {
            this.setState({ showSpinner: false })
        }, 2000)
    }

    _closeLogicModal = () => {
        this.setState({ _logicModalVisible: false })
    }

    _logicFunctionToExecute = () => {
        const { _logicIndex } = this.state;
        const link2 = 'https://honghanaconsulate.bm/wp-content/uploads/Parental-Consent-FormSingle.pdf';
        const link1 = 'https://honghanaconsulate.bm/wp-content/uploads/Parental-Consent-FormJoint.pdf';
        const link3 = 'https://honghanaconsulate.bm/wp-content/uploads/Press-Accreditation-Form.pdf';
        // window.open(link1)

        let func;

        switch (_logicIndex) {
            case 0:
                func = () => {
                    window.open(link1)
                    window.open(link2)
                }
                break;
            case 1:
                func = () => { return }
                break;

            case 2:
                func = () => window.open(link3)
                break;

            default:
                console.log(' ')
        }

        return func;

    }

    /*
    toastMessage = (message, link) => {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            className: 'toast-message',
            autoClose: false,
            onClick: () => {
                if (link.length > 5) {
                    window.open(link)
                }
            }
        });
    }
    */



    componentDidMount() {

        // // console.log('Form Mounted')
        // console.log(_.isEmpty(location.state))
        const isLocationEmpty = _.isEmpty(location.state);
        // console.log(location.state)
        // IF LOCATION IS EMPTY, IT MEANS THE FORM HAS NOT BEEN REVIEWED

        if (!isLocationEmpty) {
            if (location.state.pageIndex != null) {
                // console.log('Index Passed is -> ' + location.state.pageIndex)

                // SEARCH FOR INPUT ELEMENT AND ADD STYLE DUE TO ERROR
                this.setState({ ...location.state.formData, pageIndex: location.state.pageIndex, navigate: false }, () => {
                    // console.log('error index -> ' + location.state.errorIndex)
                    // console.log('input object is ' + IndexToName[location.state.errorIndex].id)

                    // INPUTS WITH YES OR NO HAVE THE ID ATTACHED TO THE PARENT IN ORDER TO STYLE THE BORDER AROUND IT.
                    if (outlineBorderInputs.includes(location.state.errorIndex)) {
                        document.getElementById(IndexToName[location.state.errorIndex].id).classList.add('u-error-border')

                        window.scrollTo({
                            left: 0,
                            top: document.getElementById(IndexToName[location.state.errorIndex].id).getBoundingClientRect().top - 150,
                            // behavior: 'smooth',

                        })

                    } else {
                        document.getElementById(IndexToName[location.state.errorIndex].id).classList.add('u-error-background')

                        document.getElementById(IndexToName[location.state.errorIndex].id).focus()
                    }

                    // document.getElementById(IndexToName[location.state.errorIndex].id).scrollIntoView()
                    /*
                    
                    */



                })



            } else {
                // USER PRESSED THE BACK FROM THE TEMPLATE REVIEW PAGE, SO HMOVE TO LAST PAGE
                // console.log('No Index Passed here')
                this.setState({ ...location.state.formData, pageIndex: 2, navigate: false })

            }




        } else {
            // That means we are freshly mounted so create unique ID for the application
            const ID = this.makeID()
            const accesscode = this.makeCode()
            // console.log(ID);
            this.setState({ ID, accesscode }, () => {
                // console.log('Your State ID is : ' + this.state.ID + ' and your code is ' + this.state.accesscode)
            })
        }



    }


    /*
    if (location.state.formData.data.firstname === '') {
        this.setState({ ...location.state.formData.data })

    }
    */



    componentDidUpdate() {
        // console.log('Form Updated')

    }

    handleChange(e) {
        e.preventDefault();
        const value = e.target.value;
        // console.log(value)


        this.setState({
            ...this.state,
            [e.target.id]: value
        }, () => {
            // console.log(this.state)

        });
    }



    handleChangeWithLetterOnlyCheck(e) {
        e.preventDefault();
        const value = e.target.value;
        // console.log(value);

        if (e.target.value.match('^[a-z A-z]*$') != null) {
            this.setState({
                ...this.state,
                [e.target.id]: value
            }, () => {

            });

        }

    }

    handleChangeWithNumberOnlyCheck(e) {
        e.preventDefault();
        const value = e.target.value;
        // console.log(value);

        if (e.target.value.match('^[0-9]*$') != null) {
            this.setState({
                ...this.state,
                [e.target.id]: value
            }, () => {

            });

        }
    }

    handleSurname(e) {
        
        this.handleChangeWithLetterOnlyCheck(e)
        // console.log(this.state)
    }


    _handleMatched() {
        // console.log(applicationArray[this.state.matchIndex].id)
        this.setState({
            ID: applicationArray[this.state.matchIndex].data.ID,
            accesscode: applicationArray[this.state.matchIndex].data.accesscode,

            //  TRAVELLING INFORMATION
            visatype: applicationArray[this.state.matchIndex].data.Visatype,
            purpose: applicationArray[this.state.matchIndex].data.Purpose,
            nationality: applicationArray[this.state.matchIndex].data.Nationality,



            passporttype: applicationArray[this.state.matchIndex].data.Passport_Type,
            passportno: applicationArray[this.state.matchIndex].data.Passport_Number,
            issuedat: applicationArray[this.state.matchIndex].data.Passport_Issued_At,
            passportexpiry: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Passport_Expiry),
            othernationality: applicationArray[this.state.matchIndex].data.Other_Nationality,




            othernationality2: applicationArray[this.state.matchIndex].data.Other_Nationality2,

            otherpurpose: applicationArray[this.state.matchIndex].data.Other_Purpose,
            modeoftravel: applicationArray[this.state.matchIndex].data.Mode_Of_Travel,

            hostfullname: applicationArray[this.state.matchIndex].data.Host_FullName,
            hostaddress: applicationArray[this.state.matchIndex].data.Host_Address,
            hostcontact: applicationArray[this.state.matchIndex].data.Host_Contact,
            hostcountry: applicationArray[this.state.matchIndex].data.Host_Country,

            hotelfullname: applicationArray[this.state.matchIndex].data.Hotel_FullName,
            hoteladdress: applicationArray[this.state.matchIndex].data.Host_Address,
            hotelcountry: applicationArray[this.state.matchIndex].data.Hotel_Country,
            hotelcontact: applicationArray[this.state.matchIndex].data.Host_Contact,

            nextofkinfullname: applicationArray[this.state.matchIndex].data.NextOfKin_FullName,
            nextofkinaddress: applicationArray[this.state.matchIndex].data.NextOfKin_Address,
            nextofkincountry: applicationArray[this.state.matchIndex].data.NextOfKin,
            nextofkincontact: applicationArray[this.state.matchIndex].data.NextOfkin_Contact,




            paymethod: applicationArray[this.state.matchIndex].data.Pay_Method,
            fundingsource: applicationArray[this.state.matchIndex].data.Funding_Source,
            previousvisit: applicationArray[this.state.matchIndex].data.Previous_Visit,
            dateofvisit: applicationArray[this.state.matchIndex].data.Date_Of_Previous_Visit,
            dateofarrival: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Expected_Arrival_Date),
            // dateofarrival: new Date(),


            // hostid: '',
            // hotelid: '',
            // nextofkin: '',

            // PERSONAL INFORMATION

            firstname: applicationArray[this.state.matchIndex].data.Firstnames,
            othername: applicationArray[this.state.matchIndex].data.Othername,
            surname: applicationArray[this.state.matchIndex].data.Surname,
            // dob: new Date(),

            dob: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Date_Of_Birth),            // date of birth
            pob: applicationArray[this.state.matchIndex].data.Place_Of_Birth,   // place of birth
            cob: applicationArray[this.state.matchIndex].data.Country_Of_Birth,   // country of birth

            contact: applicationArray[this.state.matchIndex].data.Contact,
            occupation: applicationArray[this.state.matchIndex].data.Occupation,
            residentialaddress: applicationArray[this.state.matchIndex].data.ResidentialAddress,

            employer: applicationArray[this.state.matchIndex].data.Employer,
            academic: applicationArray[this.state.matchIndex].data.Academic,
            isastudent: applicationArray[this.state.matchIndex].data.Is_A_Student,

            email: applicationArray[this.state.matchIndex].data.Email,
            confirmemail: applicationArray[this.state.matchIndex].data.Email,


            // DECLARATIONS
            deported: applicationArray[this.state.matchIndex].data.Deported_From_Country_Status,

            deportedfromgh: applicationArray[this.state.matchIndex].data.Deported_From_GH_Status,

            deportedfromghdate: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Deported_From_GH_Date),
            deportedfromghreason: applicationArray[this.state.matchIndex].data.Deported_From_GH_Reason,

            deportedcountry: applicationArray[this.state.matchIndex].data.Deported_Country,
            deporteddate: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Deported_From_Country_Date),
            deportedreason: applicationArray[this.state.matchIndex].data.Deported_From_Country_Reason,

            criminal: applicationArray[this.state.matchIndex].data.Criminal_Status,
            mental: applicationArray[this.state.matchIndex].data.Mental_Status,
            communicable: applicationArray[this.state.matchIndex].data.Communicable_Disease_Status,
            yellowfever: applicationArray[this.state.matchIndex].data.Yellow_Fever_Status,
            yellowfeverdate: this.convertFireBaseTime(applicationArray[this.state.matchIndex].data.Yellow_Fever_Date),

            _visible: false
        })

    }

    convertFireBaseTime = (x) => _.isEmpty(x) ? '' : new Date(
        x.seconds * 1000 + x.nanoseconds / 1000000,
    );

    handleCountryChange(val) {


        const filteredCountry = _.find(CountryData, ['name', val])
        // console.log(filteredCountry)

        this.setState({
            phoneCountry: val,
            selectedCountry: filteredCountry,
        })

    }

    dayDiff = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    handleVisaTypeChange(e) {
        e.preventDefault();
        // console.log(e.target.value)

        if (e.target.value.toLowerCase().includes('transit')) {
            this.setState({
                _logicModalMessage: 'This Visa choice is for transit purposes only and cannot used to enter Ghana.',
                _logicIndex: 1,
                _logicModalVisible: true,
            })

        }
        this.setState({ visatype: e.target.value }, () => {
            // console.log(this.state)
        })
    }

    handleMasterControl(id, value) {
        // console.log(value)

        const masterObjArray = masterControls.filter(x => x.id === id)
        // console.log(masterObjArray);

        if (value === "Yes") {
            masterObjArray[0].controls.map((item, index) => {
                if (!triggeredRequired.includes(item)) {
                    triggeredRequired.push(item)
                }
            })

        } else {

            masterObjArray[0].controls.map((item, index) => {
                if (triggeredRequired.includes(item)) {
                    const removeIndex = triggeredRequired.indexOf(item)
                    triggeredRequired.splice(removeIndex, 1)
                }
            })

        }

        this.setState({ [id]: value })

        // console.log(triggeredRequired);

    }

    handleStudentMasterControl(value) {
        const id = 'isastudent';
        // console.log(value)
        const masterObjArray = {
            id: 'isastudent',
            master: 9,
            pluscontrol: 10,
            minuscontrol: 11,
        };

        if (value === "Yes") {

            if (!triggeredRequired.includes(masterObjArray.pluscontrol)) {
                triggeredRequired.push(masterObjArray.pluscontrol)
            }
            if (triggeredRequired.includes(masterObjArray.minuscontrol)) {
                const removeIndex = triggeredRequired.indexOf(masterObjArray.minuscontrol)
                triggeredRequired.splice(removeIndex, 1)
            }

        } else {


            if (!triggeredRequired.includes(masterObjArray.minuscontrol)) {
                triggeredRequired.push(masterObjArray.minuscontrol)
            }
            if (triggeredRequired.includes(masterObjArray.pluscontrol)) {
                const removeIndex = triggeredRequired.indexOf(masterObjArray.pluscontrol)
                triggeredRequired.splice(removeIndex, 1)
            }
        }

        this.setState({ [id]: value }, () => {
            // console.log(this.state)
            // console.log(triggeredRequired);
        })

    }

    handlePurposeOfVisit(e) {
        // INDEXES OF EPENDENT INPUTS
        // console.log('herehere')
        e.preventDefault()
        const purposeIndex = 25;
        const hotelIndex = [34, 35, 36, 37];
        const hostIndex = [30, 31, 32, 33]
        // console.log(e.target.value)


        const masterObjArray = masterControls.filter(x => x.id === "purpose")
        // console.log(masterObjArray)
        if (this.state.visatype.toLowerCase().includes('transit')) {
            this.setState({
                _logicModalMessage: `You have previously selected a Transit Visa as the Visa Required. Your purpose will therefore have to be Transit.
                Please change your selection under Visa Required if you intend to enter Ghana.`,
                _logicIndex: 1,
                _logicModalVisible: true
            })


        } else {

            if (e.target.value === "Other") {



                // IF HOST IS ALREADY SET TRIGGERED, UNTRIGGER IT FROM THE REQUIRED
                if (this.state.purpose === "Family/Friend Visit") {
                    hostIndex.map((value, index) => {
                        triggeredRequired.splice(triggeredRequired.indexOf(value), 1)
                    })
                }
                if (!triggeredRequired.includes(purposeIndex)) {
                    triggeredRequired.push(purposeIndex)
                }
                if (!triggeredRequired.includes(hotelIndex[0])) {
                    triggeredRequired.push(...hotelIndex)
                }

                this.setState({ purpose: e.target.value })
                // console.log(triggeredRequired)
                return
            } else {
                //  MAKE SURE THE OTHER IS NOT SET TO COMPULSORY
                if (triggeredRequired.includes(purposeIndex)) {
                    triggeredRequired.splice(triggeredRequired.indexOf(purposeIndex), 1)
                    // console.log('Got rid OTHER PURPOSE REASON BEING REQUIRED')
                }
            }

            if (e.target.value === "Foreign Press" || e.target.value === "Holiday/Tourism") {

                if (this.state.purpose === "Family/Friend Visit") {
                    hostIndex.map((value, index) => {
                        triggeredRequired.splice(triggeredRequired.indexOf(value), 1)

                    })
                    triggeredRequired.push(...hotelIndex)

                } else {
                    if (!triggeredRequired.includes(hotelIndex[0])) {
                        triggeredRequired.push(...hotelIndex)
                    }
                }
                this.setState({ purpose: e.target.value })
                if (e.target.value === "Foreign Press") {
                    this.setState({
                        _logicModalMessage: 'Please download, complete and upload a Press Accreditation form.',
                        _logicIndex: 2,
                        _logicModalVisible: true,
                    })
                }
                // console.log(triggeredRequired)
                return
            }

            if (e.target.value === "Family/Friend Visit") {

                if (triggeredRequired.includes(hotelIndex[0])) {
                    hotelIndex.map((value, index) => {
                        triggeredRequired.splice(triggeredRequired.indexOf(value), 1)

                    })

                }

                if (!triggeredRequired.includes(hostIndex[0])) {
                    triggeredRequired.push(...hostIndex)
                }

                this.setState({ purpose: e.target.value })
                // console.log(triggeredRequired)
                return
            }

            if (e.target.value === 'Transit') {


                if (triggeredRequired.includes(hotelIndex[0])) {
                    hotelIndex.map((value, index) => {
                        triggeredRequired.splice(triggeredRequired.indexOf(value), 1)

                    })

                }

                if (triggeredRequired.includes(hostIndex[0])) {
                    hostIndex.map((value, index) => {
                        triggeredRequired.splice(triggeredRequired.indexOf(value), 1)

                    })

                }

                if (triggeredRequired.includes(purposeIndex)) {
                    triggeredRequired.splice(triggeredRequired.indexOf(purposeIndex), 1)
                    // console.log('Got rid OTHER PURPOSE REASON BEING REQUIRED')
                }
                this.setState({ purpose: e.target.value });
                // console.log(triggeredRequired)

                return


            }

        }


    }

    handleContact(e) {
        e.preventDefault();
        let value = e.target.value;
        // console.log(value)
        if (value.length < 8) {

            if (e.target.value.match('^[0-9]*$') != null) {
                this.setState({
                    ...this.state,
                    [e.target.id]: value
                }, () => {

                });
            }
        }
    }

    handleNationality(val) {
        const { passporttype } = this.state
        this.setState({ nationality: val }, () => {
            if (passporttype !== '') {
                this.passportTypeAndNationalityCheck()
            }
        })

    }

    handlePassportType(e) {
        const { nationality } = this.state
        e.preventDefault();
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        }, () => {
            if (nationality !== '') {
                this.passportTypeAndNationalityCheck()
            }
        });
    }

    passportTypeAndNationalityCheck() {
        const { nationality, passporttype } = this.state;

        switch (passporttype) {
            case 'Ordinary Passport':
                if (ordinaryPassportPass.includes(nationality)) {
                    this.setState({
                        _logicModalMessage: `You do not require a Visa for Travel to Ghana if you are traveling on a Ordinary Passport issued by ${nationality}.`,
                        _logicIndex: 1,
                        _logicModalVisible: true
                    })
                }
                break;

            case 'Diplomatic Passports':
                if (diplomaticPassportPass.includes(nationality)) {
                    this.setState({
                        _logicModalMessage: `You do not require a Visa for Travel to Ghana if you are traveling on a Diplomatic Passport issued by ${nationality}.`,
                        _logicIndex: 1,
                        _logicModalVisible: true
                    })
                }
                break;

            case 'Official Passport':
                if (officialPassportPass.includes(nationality)) {
                    this.setState({
                        _logicModalMessage: `You do not require a Visa for Travel to Ghana if you are traveling on an Official Passport issued by ${nationality}.`,
                        _logicIndex: 1,
                        _logicModalVisible: true
                    })
                }
                break;

            case 'Service Passport':
                if (servicePassportPass.includes(nationality)) {
                    this.setState({
                        _logicModalMessage: `You do not require a Visa for Travel to Ghana if you are traveling on a Service Passport issued by ${nationality}.`,
                        _logicIndex: 1,
                        _logicModalVisible: true
                    })
                }
                break;

            default:
                break;
        }

    }


    validateDate(date, id) {
        const today = new Date();

        const validate = today.getTime() > date.getTime();

        if (validate) {
            this.setState({ [id]: date })
        } else {
            this.setState({ [id]: '' })
        }


    }

    handleYellowFeverDate(date, id) {
        const today = new Date();

        const validate = today.getTime() > date.getTime();

        if (validate) {
            this.setState({ [id]: date }, () => this.yellowFeverCheck())
        } else {
            this.setState({ [id]: '' })
        }

    }

    validateDOB(date) {
        const today = new Date();
        const { firstname, surname } = this.state;
        // console.log(date.toDateString())

        const validate = today.getTime() > date.getTime();

        
        if (validate) {
            this.setState({ dob: date }, () => {

                const matchIndex = applicationArray.findIndex(
                                    app => app.data.Firstnames.toLowerCase() === firstname.toLowerCase()
                                    && app.data.Surname.toLowerCase() === surname.toLowerCase()
                                    && this.convertFireBaseTime(app.data.Date_Of_Birth).toDateString() === date.toDateString()
                 )
                // console.log(matchIndex)
                if (matchIndex > -1) {
                    this.setState({ _visible: true, matchIndex, matchID: applicationArray[matchIndex].id })
                }
                // console.log(applicationArray[matchIndex])
                const yrDiff = Math.abs(today.getFullYear() - date.getFullYear());
                // console.log(yrDiff)
                if (yrDiff < 18) {
                    // this.toastMessage('Please download, complete and upload a Guardian consent form.', link1)
                    this.setState({ _logicModalVisible: true })
                }
            })

        } else {
            this.setState({ dob: '' })
        }
        

    }


    validatePassportExpiry(date) {
        const today = new Date();

        const validate = today.getTime() < date.getTime();

        if (validate) {
            this.setState({ passportexpiry: date }, () => {
                const monthDiff = date.getMonth() - today.getMonth() + 12 * (date.getFullYear() - today.getFullYear())
                if (monthDiff <= 6) {
                    this.setState({
                        _logicModalMessage: 'Your passport expires within six months and is therefore not valid for travel to Ghana. Please renew your passport and re-apply.',
                        _logicIndex: 1,
                        _logicModalVisible: true
                    })
                    // this.toastMessage('Your passport expires within six months and is therefore no valid for travel to Ghana. Please renew your passport and re-apply.', link)
                }
            })
        } else {
            this.setState({ passportexpiry: '' })
        }
    }



    validateArrivalDate(date) {

        const today = new Date();
        const validate = today.getTime() < date.getTime();

        if (validate) {
            this.setState({ dateofarrival: date })
        } else {
            this.setState({ dateofarrival: '' })
        }

    }

    validateCountry = (country) => {
        const index = CountryData.findIndex(x => x.name === country)

        if (index > -1) {
            this.setState({ nextofkincountry: country })
        }
    }

    validateGhanaContacts(e) {
        // check Ghana contacts.
        e.preventDefault();
        const value = e.target.value;
        // console.log(value)
        if (value.length < 11) {

            if (e.target.value.match('^[0-9]*$') != null) {
                this.setState({
                    ...this.state,
                    [e.target.id]: value
                }, () => {

                });
            }
        }

    }

    yellowFeverCheck() {
        const { yellowfeverdate, dateofarrival } = this.state

        if (dateofarrival !== '') {


            const daysDiffBtnVaccinationAndArrival = this.dayDiff(dateofarrival, yellowfeverdate)
            // console.log(daysDiffBtnVaccinationAndArrival)
            if (daysDiffBtnVaccinationAndArrival < 10) {
                this.setState({
                    _logicModalMessage: 'You may be refused entry into Ghana unless it has been at least 10 (ten) days since your Yellow Fever Vaccination.',
                    _logicIndex: 1,
                    _logicModalVisible: true
                })
            }

        } else {
            this.setState({
                _logicModalMessage: 'Kindly Fill the value for Expected Arrival Date before entering the Yellow Fever Vaccination date to facilitate checks',
                _logicIndex: 1,
                _logicModalVisible: true,
                yellowfeverdate: ''
            })
        }
    }




    render() {

        const { pageIndex, showSpinner, navigate, isastudent, selectedCountry, previousvisit, deportedfromgh, deported, yellowfever } = this.state;

        return (
            <div className='form__section'>

                <Header />
                {navigate && <Navigate to="/preview" replace={true} state={{ data: this.state, tempRequired: triggeredRequired }} />}
                {<Spinner showSpinner={showSpinner} />}
                <UseLocation />
                <LogicModal
                    message={this.state._logicModalMessage}
                    visible={this.state._logicModalVisible}
                    handleClose={this._closeLogicModal}
                    execute={this._logicFunctionToExecute()}
                />
                <div>
                    <Rodal
                        visible={this.state._visible}
                        width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.5}
                        height={isMobile ? 340 : 420}
                        onClose={() => this.setState({ _visible: false })}
                    >
                        <div className='myrodal'>
                            <div className="myrodal__iconbox">
                                <img src={Avatar} alt="File upload sucesss" className='myrodal__icon' />
                            </div>
                            <div className="myrodal__message__big">

                                {this.state._message}

                            </div>

                            <div className="myrodal__btnContainer" onClick={() => this._handleMatched()}>
                                <button className="btn btn--normal">Ok</button>
                            </div>

                        </div>
                    </Rodal>
                </div>

                <section class="section-book">
                    <div class="row">
                        <div className="book">
                            <div class="u-margin-bottom-medium u-center-text" style={{ display: 'none', width: '0' }}>
                                <h2 class="heading-secondary">
                                    Application for Ghana Visa
                                </h2>
                            </div>
                            <div class="book__form">
                                <div class="u-margin-bottom-medium">
                                    <h2 class="heading-primary" style={showSpinner ? { display: 'none', width: '0' } : {}}>
                                        {pageIndex === 0 ? 'Personal Information' : (pageIndex === 1 ? 'Travel Information' : 'Declaration')}
                                    </h2>
                                </div>



                                <div class="form" style={showSpinner ? { display: 'none', height: '0', width: '0' } : {}}>

                                    {/*  PERSONAL INFORMATION */}

                                    <div className="form__container" style={pageIndex === 0 ? {} : { display: 'none', width: '0' }}>
                                        <div class="form__group">
                                            <label for="firstname" class="form__label">Firstnames &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Firstnames"
                                                id="firstname"
                                                name="firstname"
                                                required
                                                pattern="/^[a-zA-Z]+$/"
                                                value={this.state.firstname}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}

                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="othername" class="form__label">Other Names</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Other Names"
                                                id="othername"
                                                required
                                                value={this.state.othername}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="surname" class="form__label">Surname &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Surname"
                                                id="surname"
                                                required
                                                value={this.state.surname}
                                                onChange={this.handleSurname.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="dob" class="form__label">Date of Birth &#42;</label>
                                            <YearDatePicker
                                                value={this.state.dob}
                                                onChange={(date) => this.validateDOB(date)}
                                                className="form__input"
                                                format={"dd/MM/y"}
                                                id="dob"
                                                placeholderText='Date of Birth'


                                            />

                                        </div>

                                        <div class="form__group form__group">
                                            <label for="pob" class="form__label">Place of Birth &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Place of Birth"
                                                id="pob"
                                                required
                                                value={this.state.pob}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="cob" class="form__label">Country of Birth &#42;</label>

                                            <CountryDropdown
                                                value={this.state.cob}
                                                onChange={(val) => this.setState({ cob: val })}
                                                classes="form__input"
                                                id="cob"
                                            />

                                        </div>



                                        <div class="form__group">
                                            <label for="contact" class="form__label">Contact / Telephone &#42;</label>
                                            <div className='form__flag-group'>
                                                <div className='form__flag-container'>
                                                    <img src={`https://flagcdn.com/${selectedCountry.isoCode.toLowerCase()}.svg`} alt="Flag of client country" className="form__flag-item" />
                                                    <p className="form__flag-label">{selectedCountry.dialCode}</p>
                                                </div>

                                                <input
                                                    type="text"
                                                    class="form__input form__input-withflag"
                                                    placeholder="Contact / Telephone"
                                                    id="contact"
                                                    required
                                                    value={this.state.contact}
                                                    onChange={this.handleContact.bind(this)}
                                                />

                                            </div>

                                        </div>

                                        <div class="form__group">
                                            <label for="occupation" class="form__label">Occupation &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Occupation"
                                                id="occupation"
                                                required
                                                value={this.state.occupation}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group form__group__full">
                                            <label for="residentialaddress" class="form__label">Full Residential Address &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Full Residential Address"
                                                id="residentialaddress"
                                                required
                                                value={this.state.residentialaddress}
                                                onChange={this.handleChange.bind(this)}
                                            />

                                        </div>

                                        <div className='form__group form__group--radioform' id="isastudent">
                                            <label for="isastudent" class="form__label form__label--radioform">ARE YOU A STUDENT &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.handleStudentMasterControl('Yes')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="isastudent1"
                                                    name="isastudent"
                                                    checked={this.state.isastudent === 'Yes'}


                                                />
                                                <label for="isastudent1" class="form__radio-label">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.handleStudentMasterControl('No')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="isastudent0"
                                                    name="isastudent"
                                                    checked={this.state.isastudent === 'No'}

                                                />
                                                <label for="isastudent0" class="form__radio-label">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>



                                        <div class="form__group form__group__full">
                                            {
                                                isastudent === "Yes" ? <label for="academic" class="form__label">Name Of Academic Instituition &#42;</label> : <label for="academic" class="form__label">Name Of Academic Instituition</label>
                                            }

                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder='Name Of Academic Instituition'
                                                id="academic"
                                                required
                                                // readOnly = {isastudent === "Yes" ? false : true}
                                                value={this.state.academic}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                disabled={isastudent === 'No' || isastudent === ""}
                                            />

                                        </div>

                                        <div class="form__group form__group__full">
                                            {
                                                isastudent === "No" ? <label for="employer" class="form__label">Name of Employer / Previous Employer &#42;</label> : <label for="employer" class="form__label">Name of Employer / Previous Employer</label>
                                            }

                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Name of Employer / Previous Employer"
                                                id="employer"
                                                required
                                                value={this.state.employer}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                disabled={isastudent === 'Yes' || isastudent === ""}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="email" class="form__label">Contact Email &#42;</label>
                                            <input
                                                type="email"
                                                class="form__input"
                                                placeholder="Contact Email"
                                                id="email"
                                                required
                                                value={this.state.email}
                                                onChange={this.handleChange.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="confirmemail" class="form__label">Confirm Contact Email &#42;</label>
                                            <input
                                                type="email"
                                                class="form__input"
                                                placeholder="Confirm Contact Email"
                                                id="confirmemail"
                                                required
                                                value={this.state.confirmemail}
                                                onChange={this.handleChange.bind(this)}
                                            />

                                        </div>

                                    </div>


                                    {/*  TRAVEL INFORMATION */}
                                    <div className="form__container" style={pageIndex === 1 ? {} : { display: 'none', width: '0' }}>
                                        <div class="form__group">
                                            <label for="visatype" class="form__label">Visa Required  &#42;</label>
                                            {/*
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Visa Required"
                                                id="visatype"
                                                required
                                                value={this.state.visatype}
                                                onChange={this.handleChange.bind(this)}

                                            />
                                        */}
                                            <select
                                                value={this.state.visatype}
                                                onChange={this.handleVisaTypeChange.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="visatype"

                                            >
                                                <option value="" disabled selected>Select Type of visa</option>
                                                {
                                                    feesArray.map((fee, index) => (
                                                        <option value={`${fee.title} ${fee.price}`}>{fee.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fee.price}</option>
                                                    ))
                                                }


                                            </select>

                                        </div>

                                        {/*

                                        <div className='form__group form__group__full form__group--radioform' id="paymethod">
                                            <label for="paymethod" class="form__label form__label--radioform">Method of Payment &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.setState({ paymethod: 'BNTB Counter Payment' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="paymethod1"
                                                    name="paymethod"
                                                    checked={this.state.paymethod === 'BNTB Counter Payment'}


                                                />
                                                <label for="paymethod1" class="form__radio-label form__radio-label--small">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    <span>BNTB Counter Payment</span>

                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.setState({ paymethod: 'BNTB Online Transfer' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="paymethod0"
                                                    name="paymethod"
                                                    checked={this.state.paymethod === 'BNTB Online Transfer'}

                                                />
                                                <label for="paymethod0" class="form__radio-label form__radio-label--small">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    <span>BNTB Online Transfer</span>

                                                </label>
                                            </div>


                                        </div>
                                        */}

                                        <div class="form__group">
                                            <label for="passporttype" class="form__label">Method of Payment &#42;</label>

                                            <select
                                                value={this.state.paymethod}
                                                onChange={this.handleChange.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="paymethod"
                                                name="paymethod"
                                            >
                                                <option value="" disabled selected>Select Method Of Payment</option>
                                                <option value="BNTB Counter Payment">BNTB Counter Payment</option>
                                                <option value="BNTB Online Transfer">BNTB Online Transfer</option>
                                                {/*<option value="Cash">Cash</option>*/}

                                            </select>

                                        </div>


                                        <div className='form__group form__group--radioform' id="previousvisit">
                                            <label for="previousvisit" class="form__label form__label--radioform">Previously Visited Ghana &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('previousvisit', 'Yes')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="previousvisit1"
                                                    name="previousvisit"
                                                    checked={previousvisit === 'Yes'}


                                                />
                                                <label for="previousvisit1" class="form__radio-label">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('previousvisit', 'No')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="previousvisit0"
                                                    name="previousvisit"
                                                    checked={previousvisit === 'No'}

                                                />
                                                <label for="previousvisit0" class="form__radio-label">
                                                    <span class="form__radio-button form__radio-button--white"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form__group">
                                            {
                                                previousvisit === "Yes" ? <label for="dateofvisit" class="form__label">Date of Last visit &#42;</label> : <label for="dateofvisit" class="form__label">Date of Last visit</label>
                                            }

                                            <YearDatePicker
                                                value={this.state.dateofvisit}
                                                onChange={(date) => this.validateDate(date, 'dateofvisit')}
                                                className="form__input"
                                                format={"dd/MM/y"}
                                                id="dateofvisit"
                                                placeholderText='Date of Last Visit'
                                                disabled={previousvisit === 'No' || previousvisit === ""}


                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="nationality" class="form__label">Travel Passport Nationality &#42;</label>

                                            <CountryDropdown
                                                value={this.state.nationality}
                                                onChange={(val) => this.handleNationality(val)}
                                                classes="form__input"
                                                id="nationality"
                                            />

                                        </div>





                                        <div class="form__group">
                                            <label for="passporttype" class="form__label">Type of Passport &#42;</label>

                                            <select
                                                value={this.state.passporttype}
                                                onChange={this.handlePassportType.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="passporttype"
                                                name="passporttype"
                                            >
                                                <option value="" disabled selected>Select Type of Passport</option>
                                                <option value="Alien Passport">Alien Passport</option>
                                                <option value="Diplomatic Passports">Diplomatic Passports</option>
                                                <option value="Official Passport">Official Passport</option>
                                                <option value="Ordinary Passport">Ordinary Passport</option>
                                                <option value="Service Passport">Service Passport</option>
                                                <option value="Travel Document">Travel Document</option>
                                            </select>

                                        </div>




                                        <div class="form__group form__group__full">
                                            <label for="passportno" class="form__label">Passport Number &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Passport Number"
                                                id="passportno"
                                                required
                                                value={this.state.passportno}
                                                onChange={this.handleChange.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="issuedat" class="form__label">Place Of Issue Of Passport &#42;</label>
                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Passport Issued At"
                                                id="issuedat"
                                                required
                                                value={this.state.issuedat}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="passportexpiry" class="form__label">Passport Expiry &#42;</label>

                                            <YearDatePicker
                                                value={this.state.passportexpiry}
                                                onChange={(date) => this.validatePassportExpiry(date)}
                                                className="form__input"
                                                format={"dd/MM/y"}
                                                id="passportexpiry"
                                                placeholderText='Passport Expiry Date'


                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="othernationality" class="form__label">Other / Prev. Nationality (1)</label>

                                            <CountryDropdown
                                                value={this.state.othernationality}
                                                onChange={(val) => this.setState({ othernationality: val })}
                                                classes="form__input"
                                                id="othernationality"
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="othernationality2" class="form__label">Other / Prev. Nationality (2)</label>

                                            <CountryDropdown
                                                value={this.state.othernationality2}
                                                onChange={(val) => this.setState({ othernationality2: val })}
                                                classes="form__input"
                                                id="othernationality2"
                                            />

                                        </div>

                                        <div class="form__group">
                                            <label for="purpose" class="form__label">Purpose of trip &#42;</label>

                                            <select
                                                value={this.state.purpose}
                                                onChange={this.handlePurposeOfVisit.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="purpose"
                                                name="purpose"
                                            >
                                                <option value="" disabled selected>Select Purpose of Trip</option>
                                                <option value="Family/Friend Visit">Family/Friend Visit</option>
                                                <option value="Foreign Press">Foreign Press</option>
                                                <option value="Holiday/Tourism">Holiday/Tourism</option>
                                                <option value="Transit">Transit</option>
                                                <option value="Other">Other</option>
                                            </select>

                                        </div>


                                        <div class="form__group">
                                            <label for="dateofarrival" class="form__label">EXPECTED ARRIVAL DATE &#42;</label>

                                            <YearDatePicker
                                                value={this.state.dateofarrival}
                                                onChange={(date) => this.validateArrivalDate(date)}
                                                className="form__input"
                                                format={"dd/MM/y"}
                                                id="dateofarrival"
                                                placeholderText='Expected Arrival Date'
                                            />

                                        </div>



                                        <div class="form__group form__group__full">
                                            {this.state.purpose === "Other" ?
                                                <label for="otherpurpose" class="form__label">If Other (Please State) &#42;</label>
                                                : <label for="otherpurpose" class="form__label">If Other (Please State)</label>
                                            }

                                            <input
                                                type="text"
                                                class="form__input"
                                                placeholder="Please State Purpose of Travel"
                                                id="otherpurpose"
                                                required
                                                value={this.state.otherpurpose}
                                                onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                disabled={this.state.purpose !== 'Other'}
                                            />

                                        </div>



                                        <div class="form__group">
                                            <label for="fundingsource" class="form__label">Source of Funding &#42;</label>

                                            <select
                                                value={this.state.fundingsource}
                                                onChange={this.handleChange.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="fundingsource"
                                                name="fundingsource"
                                            >
                                                <option value="" disabled selected>Select Source of Funding</option>
                                                <option value="Credit / Debit Card">Credit / Debit Card</option>
                                                <option value="Personal Cash">Personal Cash</option>
                                                <option value="Travellers Cheque">Travellers Cheque</option>
                                            </select>

                                        </div>

                                        <div class="form__group">
                                            <label for="modeoftravel" class="form__label">Mode Of Travel &#42;</label>

                                            <select
                                                value={this.state.modeoftravel}
                                                onChange={this.handleChange.bind(this)}
                                                className="form__input form__dropdown-input"
                                                id="modeoftravel"
                                                name="modeoftravel"
                                            >
                                                <option value="" disabled selected>Select Mode Of Travel</option>
                                                <option value="Air">Air</option>
                                                <option value="Sea">Sea</option>
                                                <option value="Land">Land</option>
                                            </select>

                                        </div>



                                        <div class="form__group form__group__41 ">
                                            <label for="host" class="form__label">
                                                {this.state.purpose === "Family/Friend Visit" ?
                                                    <label for="hostfullname" class="form__label">NAME & CONTACT OF PERSON IN GHANA &#42;</label>

                                                    : <label for="hostfullname" class="form__label">NAME & CONTACT OF PERSON IN GHANA</label>
                                                }
                                            </label>
                                            <div className='form__group__41__container'>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Full Name"
                                                        id="hostfullname"
                                                        required
                                                        value={this.state.hostfullname}
                                                        onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                        disabled={this.state.purpose === 'Holiday/Tourism' || this.state.purpose === 'Foreign Press' || this.state.purpose === "Other" || this.state.purpose === "" || this.state.purpose === 'Transit'}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Address"
                                                        id="hostaddress"
                                                        required
                                                        value={this.state.hostaddress}
                                                        onChange={this.handleChange.bind(this)}
                                                        disabled={this.state.purpose === 'Holiday/Tourism' || this.state.purpose === 'Foreign Press' || this.state.purpose === "Other" || this.state.purpose === "" || this.state.purpose === 'Transit'}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>

                                                    <div className='form__flag-group'>

                                                        <CountryDropdown
                                                            value={this.state.hostcountry}
                                                            onChange={(val) => this.setState({ hostcountry: val })}
                                                            classes="form__input form__input--41"
                                                            id="hostcountry"
                                                            name='hostcountry'
                                                            defaultOptionLabel={this.state.hostcountry}
                                                            disabled
                                                        // disabled={this.state.purpose === 'Holiday/Tourism' || this.state.purpose === 'Foreign Press' ||this.state.purpose === "Other" || this.state.purpose === ""|| this.state.purpose === 'Transit'}

                                                        />

                                                        {/* 
                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="hostcontact"
                                                            required
                                                            value={this.state.hostcontact}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                        */}


                                                    </div>
                                                </div>

                                                <div className='form__inputbox'>

                                                    <div className='form__flag-group'>
                                                        <div
                                                            className='form__flag-container'
                                                            style={
                                                                (this.state.purpose === 'Holiday/Tourism' ||
                                                                    this.state.purpose === 'Foreign Press' ||
                                                                    this.state.purpose === "Other" ||
                                                                    this.state.purpose === 'Transit' ||
                                                                    this.state.purpose === "") ? {
                                                                    // display: 'none',

                                                                } : {

                                                                }
                                                            }
                                                        >
                                                            <img src={`https://flagcdn.com/${_.find(CountryData, ['name', this.state.hostcountry]).isoCode.toLowerCase()}.svg`} alt="Flag of client country" className="form__flag-item" />
                                                            <p className="form__flag-label">{_.find(CountryData, ['name', this.state.hostcountry]).dialCode}</p>
                                                        </div>

                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="hostcontact"
                                                            required
                                                            value={this.state.hostcontact}
                                                            onChange={this.validateGhanaContacts.bind(this)}
                                                            disabled={this.state.purpose === 'Holiday/Tourism' || this.state.purpose === 'Foreign Press' || this.state.purpose === "Other" || this.state.purpose === "" || this.state.purpose === 'Transit'}
                                                        />

                                                    </div>
                                                </div>
                                            </div>


                                        </div>



                                        <div class="form__group form__group__41 ">
                                            {this.state.purpose === "Foreign Press" || this.state.purpose === "Holiday/Tourism" || this.state.purpose === "Other" ?
                                                <label for="hotelfullname" class="form__label">Address/Hotel Where Applicant Will Be Staying &#42;</label>
                                                : <label for="hotelfullname" class="form__label">Address/Hotel Where Applicant Will Be Staying</label>
                                            }

                                            <div className='form__group__41__container'>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Full Name"
                                                        id="hotelfullname"
                                                        required
                                                        value={this.state.hotelfullname}
                                                        onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                        disabled={this.state.purpose === 'Family/Friend Visit' || this.state.purpose === 'Transit' || this.state.purpose === ""}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Address"
                                                        id="hoteladdress"
                                                        required
                                                        value={this.state.hoteladdress}
                                                        onChange={this.handleChange.bind(this)}
                                                        disabled={this.state.purpose === 'Family/Friend Visit' || this.state.purpose === 'Transit' || this.state.purpose === ""}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>



                                                    <div className='form__flag-group'>


                                                        <CountryDropdown
                                                            value={this.state.hotelcountry}
                                                            onChange={(val) => this.setState({ hotelcountry: val })}
                                                            classes="form__input form__input--41"
                                                            id="hotelcountry"
                                                            name='hotelcountry'
                                                            defaultOptionLabel={this.state.hotelcountry}
                                                            disabled
                                                        // disabled={this.state.purpose === 'Family/Friend Visit' || this.state.purpose === 'Transit' || this.state.purpose === ""}

                                                        />

                                                        {/* 
                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="hostcontact"
                                                            required
                                                            value={this.state.hostcontact}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                        */}


                                                    </div>
                                                </div>

                                                <div className='form__inputbox'>

                                                    <div className='form__flag-group'>
                                                        <div
                                                            className='form__flag-container'
                                                            style={
                                                                (this.state.purpose === 'Family/Friend Visit' ||
                                                                    this.state.purpose === 'Transit' ||

                                                                    this.state.purpose === "") ? {
                                                                    // display: 'none',

                                                                } : {

                                                                }
                                                            }
                                                        >
                                                            <img src={`https://flagcdn.com/${_.find(CountryData, ['name', this.state.hotelcountry]).isoCode.toLowerCase()}.svg`} alt="Flag of client country" className="form__flag-item" />
                                                            <p className="form__flag-label">{_.find(CountryData, ['name', this.state.hotelcountry]).dialCode}</p>
                                                        </div>

                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="hotelcontact"
                                                            required
                                                            value={this.state.hotelcontact}
                                                            onChange={this.validateGhanaContacts.bind(this)}
                                                            disabled={this.state.purpose === 'Family/Friend Visit' || this.state.purpose === 'Transit' || this.state.purpose === ""}
                                                        />

                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="form__group form__group__41 ">
                                            <label for="nextofkinfullname" class="form__label">Name & Contact of Next-of-Kin &#42;</label>
                                            <div className='form__group__41__container'>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Full Name"
                                                        id="nextofkinfullname"
                                                        required
                                                        value={this.state.nextofkinfullname}
                                                        onChange={this.handleChangeWithLetterOnlyCheck.bind(this)}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>
                                                    <input
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Address"
                                                        id="nextofkinaddress"
                                                        required
                                                        value={this.state.nextofkinaddress}
                                                        onChange={this.handleChange.bind(this)}
                                                    />
                                                </div>

                                                <div className='form__inputbox'>



                                                    <div className='form__flag-group'>


                                                        <CountryDropdown
                                                            value={this.state.nextofkincountry}
                                                            onChange={(val) => this.validateCountry(val)}
                                                            classes="form__input form__input--41"
                                                            id="nextofkincountry"
                                                            name='nextofkincountry'
                                                            defaultOptionLabel={this.state.nextofkincountry}

                                                        />

                                                        {/* 
                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="hostcontact"
                                                            required
                                                            value={this.state.hostcontact}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                        */}


                                                    </div>
                                                </div>

                                                <div className='form__inputbox'>

                                                    <div className='form__flag-group'>
                                                        <div className='form__flag-container'>
                                                            <img src={`https://flagcdn.com/${_.find(CountryData, ['name', this.state.nextofkincountry]).isoCode.toLowerCase()}.svg`} alt="Flag of client country" className="form__flag-item" />
                                                            <p className="form__flag-label">{_.find(CountryData, ['name', this.state.nextofkincountry]).dialCode}</p>
                                                        </div>

                                                        <input
                                                            type="text"
                                                            class="form__input form__input-withflag"
                                                            placeholder="Contact / Telephone"
                                                            id="nextofkincontact"
                                                            required
                                                            value={this.state.nextofkincontact}
                                                            onChange={this.handleChangeWithNumberOnlyCheck.bind(this)}
                                                        />

                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </div>


                                    {/*  DECLARATIONS */}

                                    <div className="form__container u-margin-bottom-big" style={pageIndex === 2 ? {} : { display: 'none', width: '0' }}>


                                        <div className='form__radio form__group__full' id="deportedfromgh">
                                            <label for="deportedfromgh" class="form__label">Ever been refused entry to / deported from Ghana ? &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('deportedfromgh', 'Yes')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="deportedfromgh1"
                                                    name="deportedfromgh"
                                                    checked={this.state.deportedfromgh === 'Yes'}


                                                />
                                                <label for="deportedfromgh1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('deportedfromgh', 'No')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="deportedfromgh0"
                                                    name="deportedfromgh"
                                                    checked={this.state.deportedfromgh === 'No'}

                                                />
                                                <label for="deportedfromgh0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form__radio form__group__full form__group__41 ">
                                            {
                                                deportedfromgh === "Yes" ? <label for="deporteddetails" class="form__label">IF YES, STATE DATE AND REASON(S) &#42;</label> : <label for="deporteddetails" class="form__label">IF YES, STATE DATE AND REASON(S)</label>
                                            }

                                            <div className='form__group__41__container'>



                                                <div className='form__inputbox form__inputbox__textarea--22'>

                                                    <YearDatePicker
                                                        value={this.state.deportedfromghdate}
                                                        onChange={(date) => this.validateDate(date, 'deportedfromghdate')}
                                                        className="form__input"
                                                        format={"dd/MM/y"}
                                                        placeholderText="Date Deported"
                                                        id='deportedfromghdate'
                                                        disabled={deportedfromgh === 'No' || deportedfromgh === ""}


                                                    />


                                                </div>


                                                <div className='form__inputbox form__inputbox__textarea--22'>
                                                    <textarea
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Reason(s)"
                                                        id="deportedfromghreason"
                                                        required
                                                        value={this.state.deportedfromghreason}
                                                        onChange={this.handleChange.bind(this)}
                                                        disabled={deportedfromgh === 'No' || deportedfromgh === ""}

                                                    />
                                                </div>

                                            </div>


                                        </div>

                                        <div className='form__radio form__group__full' id="deported">
                                            <label for="deported" class="form__label">EVER BEEN REFUSED ENTRY TO / DEPORTED FROM ANY COUNTRY ? &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('deported', 'Yes')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="deported1"
                                                    name="deported"
                                                    checked={this.state.deported === 'Yes'}


                                                />
                                                <label for="deported1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.handleMasterControl('deported', 'No')}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="deported0"
                                                    name="deported"
                                                    checked={this.state.deported === 'No'}

                                                />
                                                <label for="deported0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form__radio form__group__full form__group__41 ">
                                            {
                                                deported === "Yes" ? <label for="deporteddetails2" class="form__label">IF YES, STATE COUNTRY, DATE AND REASON(S) &#42;</label> : <label for="deporteddetails2" class="form__label">IF YES, STATE COUNTRY, DATE AND REASON(S)</label>
                                            }

                                            <div className='form__group__41__container'>

                                                <div className='form__flag-group form__inputbox__textarea--12'>
                                                    <CountryDropdown
                                                        value={this.state.deportedcountry}
                                                        onChange={(val) => this.setState({ deportedcountry: val })}
                                                        classes="form__input form__input--41"
                                                        id="deportedcountry"
                                                        name='deportedcountry'
                                                        disabled={deported === 'No' || deported === ""}


                                                    />
                                                </div>

                                                <div className='form__inputbox form__inputbox__textarea--12'>


                                                    <YearDatePicker
                                                        value={this.state.deporteddate}
                                                        onChange={(date) => this.validateDate(date, 'deporteddate')}
                                                        className="form__input"
                                                        format={"dd/MM/y"}
                                                        placeholderText="Date Deported"
                                                        id="deporteddate"
                                                        disabled={deported === 'No' || deported === ""}

                                                    />
                                                </div>


                                                <div className='form__inputbox form__inputbox__textarea--22'>
                                                    <textarea
                                                        type="text"
                                                        class="form__input form__input--41"
                                                        placeholder="Reason(s)"
                                                        id="deportedreason"
                                                        required
                                                        value={this.state.deportedreason}
                                                        onChange={this.handleChange.bind(this)}
                                                        disabled={deported === 'No' || deported === ""}

                                                    />
                                                </div>

                                            </div>


                                        </div>





                                        <div className='form__radio' id="criminal">
                                            <label for="criminal" class="form__label">Do you have a criminal record in any country ? &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.setState({ criminal: 'Yes' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="criminal1"
                                                    name="criminal"
                                                    checked={this.state.criminal === 'Yes'}
                                                />
                                                <label for="criminal1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.setState({ criminal: 'No' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="criminal0"
                                                    name="criminal"
                                                    checked={this.state.criminal === 'No'}
                                                />
                                                <label for="criminal0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className='form__radio' id="mental">
                                            <label for="mental" class="form__label">Do you suffer from any mental Disorders ? &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.setState({ mental: 'Yes' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="mental1"
                                                    name="mental"
                                                    checked={this.state.mental === 'Yes'}
                                                />
                                                <label for="mental1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.setState({ mental: 'No' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="mental0"
                                                    name="mental"
                                                    checked={this.state.mental === 'No'}
                                                />
                                                <label for="mental0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className='form__radio form__group__full' id="communicable">
                                            <label for="communicable" class="form__label">Do you suffer from any communicable diseases ? &#42;</label>
                                            <div class="form__radio-group" onClick={() => this.setState({ communicable: 'Yes' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="communicable1"
                                                    name="communicable"
                                                    checked={this.state.communicable === 'Yes'}
                                                />
                                                <label for="communicable1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div class="form__radio-group" onClick={() => this.setState({ communicable: 'No' })}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="communicable0"
                                                    name="communicable"
                                                    checked={this.state.communicable === 'No'}
                                                />
                                                <label for="communicable0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className='form__radio form__group__full' id="yellowfever">

                                            <label for="yellowfever" class="form__label">Have you had Yellow Fever Vaccination ? &#42;</label>
                                            <div
                                                class="form__radio-group"
                                                onClick={() => {
                                                    this.handleMasterControl('yellowfever', 'Yes')
                                                }}>
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="yellowfever1"
                                                    name="yellowfever"
                                                    checked={this.state.yellowfever === 'Yes'}
                                                />
                                                <label for="yellowfever1" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    Yes
                                                </label>
                                            </div>

                                            <div
                                                class="form__radio-group"
                                                onClick={() => {
                                                    this.handleMasterControl('yellowfever', 'No')
                                                    this.setState({
                                                        _logicModalMessage: 'You will be refused entry into Ghana unless you have proof of a Yellow Fever Vaccination and it has been at least 10 (ten) days since your vaccination.',
                                                        _logicIndex: 1,
                                                        _logicModalVisible: true
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    class="form__radio-input"
                                                    id="yellowfever0"
                                                    name="yellowfever"
                                                    checked={this.state.yellowfever === 'No'}
                                                />
                                                <label for="yellowfever0" class="form__radio-label">
                                                    <span class="form__radio-button"></span>
                                                    No
                                                </label>
                                            </div>
                                        </div>


                                        <div class="form__radio form__group__full">
                                            {
                                                yellowfever === "Yes" ? <label for="yellowfeverdate" class="form__label">IF YES, STATE DATE OF VACCINATION &#42;</label> : <label for="yellowfeverdate" class="form__label">IF YES, STATE DATE OF VACCINATION</label>
                                            }



                                            <YearDatePicker
                                                value={this.state.yellowfeverdate}
                                                onChange={(date) => {
                                                    this.handleYellowFeverDate(date, 'yellowfeverdate')
                                                }
                                                }
                                                className="form__input"
                                                format={"dd/MM/y"}
                                                id="yellowfeverdate"
                                                placeholderText='Date of Vaccination'
                                                disabled={yellowfever === 'No' || yellowfever === ""}


                                            />



                                        </div>

                                    </div>

                                    <div class="form__btns">
                                        <button
                                            className="btn btn--normal"
                                            onClick={() => this.handlePrevSection()}
                                            style={pageIndex === 0 ? { width: '0', display: 'none' } : {}}
                                        // disabled={true}
                                        >&larr; Prev step </button>
                                        <button
                                            className="btn btn--normal"
                                            onClick={() => this.handleNextSection()}
                                        >
                                            {pageIndex === 2 ? 'Preview' : 'Next step'} &rarr;
                                        </button>
                                    </div>
                                </div>




                            </div>

                        </div>

                    </div>


                </section >

                <Footer />
                <ToastContainer />
            </div >
        )
    }


    handleNextSection() {
        if (this.state.pageIndex < 2) {
            this.setState({ pageIndex: this.state.pageIndex + 1 })
        } else {
            this.setState({ navigate: true })
        }
    }

    handlePrevSection() {
        if (this.state.pageIndex > 0) {
            this.setState({ pageIndex: this.state.pageIndex - 1 })
        }
    }

    makeID() {
        let text = "";

        const possibleChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const possibleNum = "0123456789";

        for (let x = 0; x < 4; x++)
            text += possibleNum.charAt(Math.floor(Math.random() * possibleNum.length));

        for (let i = 0; i < 3; i++)
            text += possibleChar.charAt(Math.floor(Math.random() * possibleChar.length));


        return 'BDA' + text;
    }

    makeCode() {
        let text = "";

        const possibleNum = "0123456789";

        for (let x = 0; x < 6; x++)
            text += possibleNum.charAt(Math.floor(Math.random() * possibleNum.length));
        return text;
    }

}

export default Form
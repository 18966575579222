import './sass/main.scss';
import Navigator from './Navigator';
import TestMobileview from './features/TestMobileview';
import { BrowserView, MobileView } from 'react-device-detect';

function App() {
  return (
    <div>

      <Navigator />

    </div>
  );
}

export default App;

import React, { Component } from 'react'
import { ClockLoader } from 'react-spinners';

export class Spinner extends Component {

  render() {
   
    return (
      <div class="spinner" style={this.props.showSpinner ?  {} : {height: '0'}}>
        <ClockLoader color='#ff7730' loading={this.props.showSpinner} size={200}/>
    </div>
    )
  }
}

export default Spinner
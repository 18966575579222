import React, { Component } from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import { isMobile } from 'react-device-detect';
import Avatar from '../../img/caution.png';


export class LogicModal extends Component {

    executeAndClose = () => {
        this.props.execute()
        this.props.handleClose()
    }

    render() {
        return (
            <div>
                <Rodal
                    visible={this.props.visible}
                    width={isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.5}
                    height={isMobile ? 340 : 420}
                    onClose={() => this.executeAndClose()}
                >
                    <div className='myrodal'>
                        <div className="myrodal__iconbox">
                            <img src={Avatar} alt="File upload sucesss" className='myrodal__icon' />
                        </div>
                        <div className="myrodal__message__big myrodal__message__big__center">

                            {this.props.message}

                        </div>

                        <div className="myrodal__btnContainer" onClick={() => this.executeAndClose()}>
                            <button className="btn btn--normal">Ok</button>
                        </div>

                    </div>
                </Rodal>
            </div>
        )
    }
}

export default LogicModal
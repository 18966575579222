import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Form from './features/Form';
import Template from './features/Template';
import UploadDocs from './features/UploadDocs';
import Login from './features/Login';

export default function Navigator() {
  return (
    <Router>


      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Routes>


        <Route path="/login/:definedID" element={<Login />} />

        <Route path="/preview" element={<Template />} />
        <Route path="/upload" element={<UploadDocs />} />
        

        <Route path="/" element={<Form />} />

      </Routes>

    </Router>
  )
}

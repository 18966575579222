import React from 'react'
import Logo from '../../img/Coat_of_arms_of_Ghana.svg.png';
import Location from '../../img/map.png';

function Footer() {
    return (
        <div className='footer'>
            <div className="footer__office">
                <div className="footer__office__icon">
                    <img src={Location} alt="Location Pin Icon" className="footer__office__icon__item" />
                </div>
                <div className="footer__office__text">
                    <p>Penthouse Floor, 129 Front Street</p>
                    <p>Hamilton, Bermuda, HM12</p>
                </div>
            </div>

            <div className="footer__copyright" style={{ display: 'none', width: '0' }}>
                <p className="footer__copyright__item">Privacy</p>
                <p className="footer__copyright__item">Terms & Conditions</p>
            </div>

            <div className="footer__logobox">
                <img src={Logo} alt="Logo of the company" className="footer__img" />
                <div className="footer__sidebox">
                    <p className="footer__logotext">Honorary Consulate of Ghana</p>
                    <p className="footer__logotext">Hamilton Bremuda</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
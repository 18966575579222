import React, { Component } from 'react'
import Logo from '../../img/Coat_of_arms_of_Ghana.svg.png';
import Phone from '../../img/phone.png';
import Mail from '../../img/message.png';
import Avatar from '../../img/ferret.png';

export class Header extends Component {
    render() {
        return (
            <div className='header'>

                <div className="header__logobox">
                    <img src={Logo} alt="Logo of the company" className="header__img" />
                    <div className="header__sidebox">
                        <p className="header__logotext">Honorary Consulate of Ghana</p>
                        <p className="header__logotext">Hamilton Bermuda</p>
                    </div>
                </div>

                <div className="header__titlebox" style={{ display: 'none', width: '0' }}>
                    <p className="header__title">Visa Application</p>
                </div>

                <div className="header__contact">
                    <div style={{ display: 'none', width: '0' }}>
                    <img src={Phone} alt="Phone number icon" className="header__contact__item" />
                    <img src={Mail} alt="Email us icon" className="header__contact__item" />
                    <img src={Avatar} alt="Avatar" className="header__contact__item" />
                    </div>
                </div>

            </div>
        )
    }
}

export default Header
import React, { Component } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { FaUserAlt, FaLock } from 'react-icons/fa'
import { HiMail } from 'react-icons/hi';
import { IoIosHelpCircle } from 'react-icons/io';
import { BsPerson } from 'react-icons/bs'
import CryptoJS from 'crypto-js'
import Spinner from './shared/Spinner';
import { db } from '../utils/firebase';
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { async } from '@firebase/util';



let linkParams;
let data = []
let hashedLink;


const GetParams = () => {
  linkParams = useParams()
  // console.log(linkParams)
  return null
}

export class Login extends Component {


  state = {
    id: '',
    code: '',
    linkID: '',
    navigate: false,
    showSpinner: false,

  }

  componentDidMount() {
    this.setState({ showSpinner: true })
    const { definedID } = linkParams;
    // console.log(definedID)
    hashedLink = definedID;
    this.decryptURL(definedID);

    /*
    const idWithSpecialCharacters = definedID.replace(/xMl3Jk/g, '+').replace(/Por21Ld/g, '/').replace(/Ml32/g, '=');
    // console.log(idWithSpecialCharacters);

    
    const bytes = CryptoJS.AES.decrypt(idWithSpecialCharacters, 'my-secret-key@123'); // SECRET KEY SHOULD BE AN ENVIRONMENT VARIABLE
    // console.log(bytes)
    const bytesString = bytes.toString();
    // console.log('Bytes string -> ' +bytesString)
    const id = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(id,  id.length);
    */

    /// // console.log(idObj)



  }

  fetchData = async () => {
    data = [];



    const applicationRef = collection(db, "applications");
    // console.log('Here fetching')
    // console.log(this.state.id)

    const q = query(applicationRef, where("ID", "==", this.state.id));
    // const querySnapshott = await getDocs(collection(db, "applications"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push({ id: doc.id, data: doc.data() })
      // console.log(doc.id, " => ", doc.data());

    });
    // console.log(data)
    this.setState({ showSpinner: false })

  }

  login() {
    const { id, code, showSpinner } = this.state;
    let message;

    this.setState({
      showSpinner: true
    }, () => {
      if (code === data[0].data.accesscode) {
        if (data[0].data.passwordUsed) {
          this.setState({ showSpinner: false, code: '' }, () => alert('Link has already been used to submit documents'))

        } else {

          this.setState({ navigate: true })
        }

      } else {
        this.setState({ showSpinner: false, code: '' }, () => alert('Wrong Access Code Entered'))

      }

    })


  }



  decryptURL(text) {

    let decrypID = new Array(10);

    decrypID[0] = text[144]
    decrypID[1] = text[7]
    decrypID[2] = text[15]
    decrypID[3] = text[21]
    decrypID[4] = text[29]
    decrypID[5] = text[36]
    decrypID[6] = text[45]
    decrypID[7] = text[50]
    decrypID[8] = text[59]
    decrypID[9] = text[63]
    /*
    decrypID[10] = text[68]
    decrypID[11] = text[72]
    decrypID[12] = text[99]
    decrypID[13] = text[123]
    decrypID[14] = text[100]
    decrypID[15] = text[178]
    decrypID[16] = text[88]
    */




    // console.log(decrypID)

    this.setState({ id: decrypID.join('') }, () => {
      // GET USERDATA FROM DB
      this.fetchData();
      // this.setPasswordUsed();

    })

  }

  handleChange(e) {

    e.preventDefault();
    // console.log(e.target.value)

    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    })
  }

  render() {
    const { navigate, showSpinner } = this.state;


    return (
      <div className='login'>
        <GetParams />
        {navigate && <Navigate to="/upload" state={{ ...data[0], link: hashedLink }} />}
        {showSpinner && <Spinner showSpinner={showSpinner} />}
        <div className="login__card" style={showSpinner ? { display: 'none', width: 0 } : {}}>

          <div className="login__card__head">
            <BsPerson className='login__card__head__icon' color="#fff" />
          </div>

          <div class="login__card__group">

            <input
              type="text"
              class="login__card__input"
              placeholder="Applicant ID"
              id="id"
              name="id"
              required
              value={this.state.id}
              onChange={this.handleChange.bind(this)}

            />
            <label for="applicantid" class="login__card__label">
              <FaUserAlt className='login__card__label__icon' />
            </label>

          </div>

          <div class="login__card__group">


            <input
              type="text"
              class="login__card__input"
              placeholder="Access code"
              id="code"
              name="code"
              required
              value={this.state.code}
              onChange={this.handleChange.bind(this)}
            />
            <label for="applicantid" class="login__card__label">
              <FaLock className='login__card__label__icon' />
            </label>

          </div>

          <div className="login__btncontainer">
            <button
              className="btn login__btn"
              onClick={() => this.login()}
            >Login</button>
          </div>

          <div className="login__card__featurecontainer" style={{ display: 'none', width: '0' }}>
            <div className="login__card__featureitem">
              <HiMail size={20} className="login__card__featureitem__img" />
              <span> Resend mail </span>
            </div>

            <div className="login__card__featureitem">
              <IoIosHelpCircle size={20} className="login__card__featureitem__img" />
              <span>Report Issue</span>

            </div>

          </div>

          <div className="login__card__cautioncontainer">
            <div className="login__card__cautionitem">
              <HiMail size={50} className="login__card__cautionitem__img" />
              <span> If you received this email by accident. Kindly close the page since it is confidential. Thank you </span>
            </div>



          </div>


        </div>

      </div>
    )
  }
}

export default Login
const ordinaryPassportPass = [
    'Barbados', 'Benin','Burkina Faso', 'Cape Verde', 'Cote DIvoire', 'Guyana', 'Guinea', 'Guinea-Bissau', 'Jamaica', 'Kenya', 
    'Lesotho', 'Liberia', 'Mali', 'Mauritius', 'Niger', 'Nigeria', 'Rwanda', 'Senegal', 'Seychelles', 'Sierra Leone', 'Singapore',
     'St. Vincent & Grenadines', 'Tanzania', 'Gambia, The', 'The Co-operative Republic of Guyana', 'Trinidad and Tobago', 'Togo', 'Uganda', 'Zimbabwe'
]

const diplomaticPassportPass = [
    'Brazil', 'Bolivia', 'China', 'Cuba', 'Equatorial Guinea', 'Germany', 'Hungary', 'India', 'Malta', 'Morocco',
     'Namibia', 'South Africa', 'St. Kitts & Nevis', 'Sudan', 'Suriname', 'United Arab Emirates', 'Venezuela',
     'Hong Kong', 'Turkey', 'Qatar'
]

const servicePassportPass = [
    'Brazil', 'Bolivia', 'China', 'Cuba', 'Equatorial Guinea', 'Germany', 'Hungary', 'India', 'Malta', 'Morocco',
     'Namibia', 'South Africa', 'St. Kitts & Nevis', 'Sudan', 'Suriname', 'United Arab Emirates', 'Venezuela',
]

const officialPassportPass = [
    'Malta'
]


export {
    ordinaryPassportPass,
    diplomaticPassportPass,
    servicePassportPass,
    officialPassportPass
}